import { Flex, Grid, Text, Box, Spinner } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import Card from './Card'
import { addCommasToNumber } from '../../../utils/helpers'
import { useGetDevicesDataQuery } from '../api/automationsApi'
import NotFound from './NotFound'
import { CURRENT_USAGE_POLLING_INTERVAL } from '../../../utils/consts'

interface DeviceProps {
    filteredGroups: any
    querySearchParams: any
}

export default function Device({ filteredGroups, querySearchParams }: DeviceProps) {
    const { data, isFetching, isLoading, refetch } = useGetDevicesDataQuery({
        from_date: querySearchParams.from,
        to_date: querySearchParams.to,
        group: filteredGroups?.map((group) => group.id.toString()),
    })

    const { currentData = [], isFetching: isCurrentDataFetching, isLoading: isCurrentDataLoading, refetch: refetchCurrentData } = useGetDevicesDataQuery(
        {
            group: filteredGroups?.map((location) => location.id.toString()),
            latest: true,
        },
        {
            pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
        },
    )

    const mergedGroups = (currentData || []).map((currentGroup) => {
        const historicalGroup = (data || []).find((group) => group.id === currentGroup.id);
        return {
            ...currentGroup,
            period_consumption: historicalGroup?.period_consumption || null,
        }
    })

    useEffect(() => {
        refetch()
        refetchCurrentData()
    }, [filteredGroups, querySearchParams, refetch, refetchCurrentData])

    const devices = mergedGroups || []

    if (isFetching || isLoading || isCurrentDataFetching || isCurrentDataLoading) {
        return <Flex justifyContent="center" alignItems="center" height="200px"><Spinner size="xl" /></Flex>
    }

    if (devices.length === 0) {
        return <NotFound title="No smart devices" description="Currently there are no devices that support automation and smart control" header={false} />
    }

    return (
        <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            {devices?.map((device) => (
                <Card title={device.title} id={device.id} key={device.id}>
                    <Flex direction="row" alignItems="center" justifyContent="center">
                        <Box width="100%" display="flex" gap={5} mt={2} justifyContent="space-between">
                            {device?.period_consumption?.total_consumption > 0 || device?.period_consumption?.total_generated_power > 0 ? (
                                <Flex direction="column">
                                    {device?.period_consumption?.total_consumption > 0 && <Text>Consumption: {addCommasToNumber(Math.round(device.period_consumption?.total_consumption || 0))} kWh</Text>}
                                    {device?.current_values?.total_consumption > 0 && <Text>Current usage: {addCommasToNumber(Math.round(device.current_values?.total_consumption || 0))} kW</Text>}
                                    {device?.period_consumption?.total_generated_power > 0 && <Text>Generated power: {addCommasToNumber(Math.round(device.period_consumption?.total_generated_power || 0))} kWh</Text>}
                                    {device?.current_values?.total_generated_power > 0 && <Text>Current generated power: {addCommasToNumber(Math.round(device.current_values?.total_generated_power || 0))} kW</Text>}
                                </Flex>
                            ) : (
                                <Flex direction="row" gap={5}>
                                    {device?.current_values?.total_co2 != null && (
                                        <Box textAlign="center">
                                            <Text fontSize="md">{device.current_values.total_co2}kg</Text>
                                            <Text fontSize="xs" color="gray.500">
                                                CO2
                                            </Text>
                                        </Box>
                                    )}
                                    {device?.current_values?.total_temperature != null && (
                                        <Box textAlign="center">
                                            <Text fontSize="md">{device.current_values.total_temperature}°C</Text>
                                            <Text fontSize="xs" color="gray.500">
                                                Temperature
                                            </Text>
                                        </Box>
                                    )}
                                    {device?.current_values?.total_humidity != null && (
                                        <Box textAlign="center">
                                            <Text fontSize="md">{device.current_values.total_humidity}%</Text>
                                            <Text fontSize="xs" color="gray.500">
                                                Humidity
                                            </Text>
                                        </Box>
                                    )}
                                    {device?.current_values?.total_illuminance != null && (
                                        <Box textAlign="center">
                                            <Text fontSize="md">{device.current_values.total_illuminance}%</Text>
                                            <Text fontSize="xs" color="gray.500">
                                                Illuminance
                                            </Text>
                                        </Box>
                                    )}
                                </Flex>
                            )}
                        </Box>
                    </Flex>
                </Card>
            ))}
        </Grid>
    )
}
