import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAlarmsCountContext } from '../../../context/AlarmsCountContext'
import {
  disconnectSocket,
  initiateSocketConnection,
  subscribeToMessages,
} from '../ws/layoutWs'
import { useDispatch } from 'react-redux'
import extendedApi from '../../reports/api/reportsApi'

const useAlertsSocket = (handleAlarms): void => {
  const location = useLocation()
  const alarmCountContext = useAlarmsCountContext()
  const dispatch = useDispatch()
  useEffect(() => {
    const token = window.localStorage.getItem('aiot_t')
    const socketServerURL = `${process.env.REACT_APP_WS_URL}/ws/alarm/?token=${token}`
    console.log('Initializing socket connection...')
    initiateSocketConnection(socketServerURL, token)

    const messageHandler = (message: any) => {
      if (typeof message === 'string') {
        try {
          const parsedMessage = JSON.parse(message)
          if (parsedMessage.data) {
            console.log('parsedMessage.data', parsedMessage.data)
            alarmCountContext.setAlarmsCount(parsedMessage.data.active_alarms)
            alarmCountContext.setAutoResolvedAlarmCount(parsedMessage.data.new_auto_resolved_alarm)
            if (location.pathname.includes('alarms')) {
              dispatch(extendedApi.util.invalidateTags(["ListAlarms"]))
            }
            
            // Toast messages are changing position due to setting alarm context :( . Seems like issue with react-toastify
            setTimeout(() => handleAlarms(parsedMessage.data), 0)
          }
        } catch (e) {
          console.error('Error parsing message:', e)
        }
      }
    }

    subscribeToMessages(messageHandler)

    return () => {
      disconnectSocket()
    }
  }, [handleAlarms, alarmCountContext, location.pathname, dispatch])
}

export default useAlertsSocket
