import { useForm } from "react-hook-form";
import CustomModal from "../../reports/pages/components/CustomModal";
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Severity from "./Severity";
import SingleSelect from "../../reports/components/SingleSelect";
import { Flex, Input, Text, HStack, Divider, Switch, VStack } from "@chakra-ui/react";
import { useGetAllLocationsQuery } from "../../reports/api/reportsApi";
import MultiSelect from "../../reports/components/MultiSelect";
import { useGetDeviceGroupListQuery } from "../../deviceConfiguration/api/deviceGroupConfigurationApi";
import { useGetMeasurementTypesQuery } from "../../locations/api/locationsApi";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import EmailInput from "../../reports/pages/components/EmailInput";
import MultistateAlarm from "./AlarmTypes/MultistateAlarm";
import { useEditAlarmMutation, useGetAllAlarmTypesQuery, useGetAllPhysicalDevicesQuery } from "../api/alarmsApi";
import LevelAlarm from "./AlarmTypes/LevelAlarm";
import { QueryActionCreatorResult, QueryDefinition, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from "@reduxjs/toolkit/query";
import { Alarm } from "../api/types";
import { ALARM_NOTIFY, STATES } from "../../../utils/consts";
import StatusAlarm from "./AlarmTypes/StatusAlarm";
import { toast } from "react-toastify";


type Props = {
    isOpen: boolean;
    alarm: Alarm;
    handleClose: () => void;
}

export default function EditAlarmModal({ isOpen, alarm, handleClose }: Props) {

    const {
        handleSubmit,
        watch,
        setValue,
        register,
        reset
    } = useForm()

    const [isChecked, setIsChecked] = useState(true)
    const [notifyWhenActive, setNotifyWhenActive] = useState(true)
    const [delay, setDelay] = useState(null)
    const [timeRange, setTimeRange] = useState({ from: "", to: "" })
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [emails, setEmails] = useState([]);
    const [editAlarm, isLoading] = useEditAlarmMutation()


    const selectedAlarmType = watch('selectedAlarmType', alarm.type) || null
    const selectedAlarmNotify = watch('selectedAlarmNotify') || null
    const selectedMeasurementType = watch('selectedMeasurementType') || null
    const selectedParameter = watch('selectedParameter') || null
    const severity = watch('severity') || []
    const selectedLocations = watch('selectedLocations') || []
    const selectedDevicesGroups = watch('selectedDevicesGroups') || []
    const selectedDevices = watch('selectedDevices') || []

    const { data: alarmTypes } = useGetAllAlarmTypesQuery(undefined, { skip: !selectedMeasurementType || !isOpen });
    const { data: locations } = useGetAllLocationsQuery({ measurementTypeId: selectedMeasurementType }, { skip: !selectedMeasurementType || !isOpen });
    const { data: measurementTypes } = useGetMeasurementTypesQuery({}, { skip: !isOpen })
    const { data: devicesGroups } = useGetDeviceGroupListQuery({ measurementTypeId: selectedMeasurementType }, { skip: !selectedMeasurementType || !isOpen });
    const { data: userPhysDevices } = useGetAllPhysicalDevicesQuery(
        {
            location: selectedLocations,
            group: selectedDevicesGroups,
            measurement: selectedMeasurementType,
        },
        { skip: (!selectedMeasurementType || selectedLocations.length === 0) || !isOpen }
    )

    const setSelectedAlarmType = (selectedAlarmType: number) => {
        setValue('selectedAlarmType', selectedAlarmType)
    }
    const setSelectedMeasurementType = (selectedMeasurementType: number) => {
        setValue('selectedMeasurementType', selectedMeasurementType)
    }
    const setSelectedParameter = (selectedParameter: string) => {
        setValue('selectedParameter', selectedParameter)
    }
    const setSelectedAlarmNotify = (selectedAlarmNotify: number) => {
        setValue('selectedAlarmNotify', selectedAlarmNotify)
        setEmails([])
        setPhoneNumber(null)
    }
    const setSelectedLocations = (selectedLocations: string[]) => {
        setValue('selectedLocations', selectedLocations)
    }
    const setSelectedDevicesGroups = (selectedDevicesGroups: string[]) => {
        setValue('selectedDevicesGroups', selectedDevicesGroups)
    }
    const setSelectedDevices = (selectedDevices: string[]) => {
        setValue('selectedDevices', selectedDevices)
    }

    useEffect(() => {
        if (alarm) {
            const notifyData = alarm.notification
            const firstKeyWithValue = Object.keys(notifyData).find(
                key => notifyData[key] && (Array.isArray(notifyData[key]) ? notifyData[key].length > 0 : true)
            )
            if (alarm?.active_hours.to && alarm?.active_hours.from) {
                setIsChecked(false)
                setTimeRange({ to: alarm?.active_hours.to, from: alarm?.active_hours.from })
            }
            let selectedAlarmNotifyId = null

            if (firstKeyWithValue === 'emails') {
                selectedAlarmNotifyId = 2
                setEmails(notifyData.emails)
            } else if (firstKeyWithValue === 'viber') {
                selectedAlarmNotifyId = 1
                setPhoneNumber(notifyData.viber)
            }
            const formValue = ({
                selectedAlarmType: alarm.type.id || null,
                selectedAlarmNotify: selectedAlarmNotifyId,
                selectedMeasurementType: alarm?.measurement_types?.id || null,
                selectedLocations: Array.from(new Set(alarm.devices.map((device) => device.location.id.toString()))) || [],
                selectedDevicesGroups: alarm.groups.map((x) => (x.id.toString())) || [],
                severity: [alarm.severity] || [],
                selectedDevices: alarm.devices.map((x) => (x.id.toString())) || [],
                alarmName: alarm.name || "",
                description: alarm.description || "",
                resolveAlarm: alarm.instructions || "",
                selectedParameter: alarm.parameter,
            });
            switch (alarm.type.id) {
                case 1:
                    //Level alarm
                    reset({
                        ...formValue,
                        value_comparison: alarm?.level_alarm.comparison_operator,
                        value: (alarm.level_alarm.comparison_value),
                        hysteresis_upper: alarm?.level_alarm.upper_border_percent,
                        hysteresis_lower: alarm?.level_alarm.bottom_border_percent,
                    })
                    break;
                case 2:
                    //Status alarm
                    reset({ ...formValue })
                    setNotifyWhenActive(alarm.status_alarm.status)
                    setDelay(alarm.status_alarm.seconds)
                    break;
                case 3:
                    //Multisate alarm
                    const isIntegerField = alarm.multistate_alarm.state === "1"
                    reset({ ...formValue, selectedState: isIntegerField ? STATES[0].id : STATES[1].id, selectedStateFields: alarm.multistate_alarm.multistate_fields })
                    break;
                default:
                    reset({ ...formValue })
                    break;
            }
        }
    }, [alarm, reset]);
    const alarmTypesSelect = useMemo(
        () =>
            alarmTypes?.results.map(({ id, name }) => ({
                id,
                name,
            })),
        [alarmTypes],
    )

    const alarmNotifySelect = ALARM_NOTIFY?.map(({ id, name }) => ({
        id,
        name,
    }))

    const devicesGroupsSelect = useMemo(
        () =>
            devicesGroups?.map(({ id, name }) => ({
                id,
                name,
            })),
        [devicesGroups],
    )

    const userDevicesSelect = useMemo(
        () =>
            userPhysDevices?.map(({ id, title }) => ({
                id,
                name: title,
            })),
        [userPhysDevices],
    )

    const measurementTypesSelect = useMemo(
        () =>
            measurementTypes?.results.map(({ id, name, parameters }) => ({
                id,
                name,
                parameters,
            })),
        [measurementTypes],
    )

    const parametersSelect = useMemo(() => {
        if (!selectedMeasurementType) return [];

        const selectedMeasurement = measurementTypesSelect?.find(({ id }) => id === selectedMeasurementType);

        return selectedMeasurement?.parameters.map((parameter) => ({
            id: parameter,
            name: parameter,
        })) || []

    }, [selectedMeasurementType, measurementTypesSelect])

    const locationSelect = locations?.results?.map((x) => {
        return { id: x.id, name: x.title }
    })


    const isStateFieldsValid = (stateFields) => {
        if (!Array.isArray(stateFields) || stateFields.length === 0) return false

        return stateFields.every(field =>
            field.value !== null &&
            field.description.trim() !== ""
        )
    }

    const isDisabled = () => {
        const alarmNameValid = watch("alarmName") === null || watch("alarmName") === undefined
        const resolveAlarmValid = watch("resolveAlarm") === null || watch("resolveAlarm") === undefined
        const selectedMeasurementTypeValid = selectedMeasurementType === null
        const selectedDevicesValid = selectedDevices.length === 0
        const selectedAlarmTypeValid = selectedAlarmType === null
        const phoneNumberValid = selectedAlarmNotify === 1 && phoneNumber?.length !== 12
        const valueComparisonValid =
            selectedAlarmType === 1 &&
            (!watch('value_comparison') ||
                !watch('value'))
        const selectedStatusValid = selectedAlarmType === 2 && !delay
        const selectedStateValid = selectedAlarmType === 3 && (!isStateFieldsValid(watch("selectedStateFields") || !watch("selectedState")))
        const timeRangeValid = !isChecked && (!timeRange?.from || !timeRange?.to)
        const severityValid = severity.length === 0 && selectedAlarmType !== 3

        return (
            severityValid ||
            alarmNameValid ||
            resolveAlarmValid ||
            selectedMeasurementTypeValid ||
            selectedDevicesValid ||
            selectedAlarmTypeValid ||
            phoneNumberValid ||
            selectedStatusValid ||
            valueComparisonValid ||
            selectedStateValid ||
            timeRangeValid
        )
    }

    const setSelectedSeverity = useCallback(
        (severity: string[]) => {
            setValue('severity', severity)
        },
        [setValue],
    )

    const handleTimeChange = (e) => {
        setTimeRange({ ...timeRange, [e.target.name]: e.target.value });
    }

    const handleInputClick = (e) => {
        e.target.showPicker()
    }

    const handleEdit = async () => {
        const toastId = toast.info('Editing alarm...', {
            style: { padding: '10px 20px' },
            autoClose: false,
            isLoading: true,
            progress: undefined,
        })
        const requestParams = {
            id: alarm.id,
            type_id: watch("selectedAlarmType"),
            devices: selectedDevices,
            groups: selectedDevicesGroups,
            severity: watch("severity").length === 0 ? "Multiple" : watch("severity")[0],
            name: watch("alarmName"),
            measurement_types_id: selectedMeasurementType,
            notification: {
                viber: phoneNumber,
                emails: emails,
            },
            active_hours: timeRange,
            description: watch("description"),
            instructions: watch("resolveAlarm"),
            parameter: selectedParameter,
        }
        if (selectedAlarmType === 1) {
            requestParams['comparison_operator'] = watch('value_comparison')
            requestParams['comparison_value'] = watch('value')
            requestParams['upper_border_percent'] = watch('hysteresis_upper')
            requestParams['bottom_border_percent'] = watch('hysteresis_lower')
        } else if (selectedAlarmType === 2) {
            requestParams['status'] = notifyWhenActive
            requestParams['seconds'] = delay
        } else {
            requestParams['multistate_fields'] = watch("selectedStateFields")
            requestParams['state'] = watch("selectedState")
        }
        try {
            await editAlarm(requestParams)
            toast.update(toastId, {
                render: `Successfully edited`,
                type: 'success',
                isLoading: false,
                autoClose: 3000,
            })
        } catch (_error) {
            toast.update(toastId, {
                render: `Failed to edit alarm!`,
                type: 'error',
                isLoading: false,
                autoClose: 3000,
            })
        }
        handleClose()
    }

    return (
        <CustomModal
            title="Edit alarm"
            tooltipLabel="Please populate the required fields first"
            handleSend={handleSubmit(() => handleEdit())}
            isDisabled={isDisabled() || !isLoading}
            isLoading={!isLoading}
            isOpen={isOpen}
            onClose={() => {
                handleClose()
                reset()
            }}
            buttonName="Save"
        >
            <Flex direction="column" gap="20px">
                {selectedAlarmType !== 3 && <Severity severity={severity} setSelectedSeverity={setSelectedSeverity} selectType="single" isRequired={severity.length === 0} />}
                <Input
                    w="full"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor={watch('alarmName') ? 'border.strong' : 'red.500'}
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id='alarmName'
                    {...register('alarmName', { required: true })}
                    placeholder="Enter alarm name"
                />
                <Input
                    w="full"
                    type="text"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor={watch('resolveAlarm') ? 'border.strong' : 'red.500'}
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id='resolveAlarm'
                    {...register('resolveAlarm', { required: true })}
                    placeholder="How to resolve the alarm?"
                />
                <Input
                    w="full"
                    type="text"
                    bg="white"
                    border="1px"
                    height="48px"
                    borderColor={watch('description') ? 'border.strong' : 'red.500'}
                    textAlign="left"
                    fontSize="18px"
                    fontWeight="normal"
                    id='description'
                    {...register('description', { required: true })}
                    placeholder="Description"
                />
                <SingleSelect
                    key="measurement-types"
                    items={measurementTypesSelect}
                    isDisabled={false}
                    {...register('selectedMeasurementTypesSelect')}
                    setSelectedOption={setSelectedMeasurementType}
                    selectedOption={selectedMeasurementType}
                    required={selectedMeasurementType === null}
                    header="Select measurement type"
                />
                {selectedMeasurementType && (
                    <>
                        <SingleSelect
                            key="parameters"
                            items={parametersSelect}
                            isDisabled={false}
                            {...register('selectedParameter')}
                            setSelectedOption={setSelectedParameter}
                            selectedOption={selectedParameter}
                            required={selectedParameter === null}
                            header="Select parameter"
                        />
                        <MultiSelect
                            items={locationSelect}
                            setSelectedOptions={setSelectedLocations}
                            selectedOptions={selectedLocations}
                            invalidateFields={['selectedDevices']}
                            setValue={setValue}
                            header={`Select location/s${selectedLocations.length > 0 ? ` (${selectedLocations.length})` : ''}`}
                        />
                        <MultiSelect
                            items={devicesGroupsSelect}
                            setSelectedOptions={setSelectedDevicesGroups}
                            selectedOptions={selectedDevicesGroups}
                            invalidateFields={['selectedDevices']}
                            setValue={setValue}
                            header={`Select device group/s${selectedDevicesGroups.length > 0
                                ? ` (${selectedDevicesGroups.length})`
                                : ''
                                }`}
                        />
                        <MultiSelect
                            items={userDevicesSelect}
                            setSelectedOptions={setSelectedDevices}
                            selectedOptions={selectedDevices}
                            required={selectedDevices.length === 0}
                            header={`Select device/s${selectedDevices.length > 0 ? ` (${selectedDevices.length})` : ''}`}
                        />
                        <SingleSelect
                            key="alarm-type"
                            items={alarmTypesSelect}
                            isDisabled={false}
                            {...register('selectedAlarmType')}
                            setSelectedOption={setSelectedAlarmType}
                            selectedOption={selectedAlarmType}
                            required={selectedAlarmType === null}
                            header="Select alarm type"
                        />
                        {selectedAlarmType === 1 && <LevelAlarm watch={watch} setValue={setValue} register={register} />}
                        {selectedAlarmType === 2 &&
                            <StatusAlarm
                                notifyWhenActive={notifyWhenActive}
                                setNotifyWhenActive={setNotifyWhenActive}
                                delay={delay}
                                setDelay={setDelay}
                            />}
                        {selectedAlarmType === 3 && <MultistateAlarm watch={watch} setValue={setValue} register={register} />}
                        <SingleSelect
                            key="alarm-notify"
                            items={alarmNotifySelect}
                            isDisabled={false}
                            {...register('selectedAlarmNotify')}
                            setSelectedOption={setSelectedAlarmNotify}
                            selectedOption={selectedAlarmNotify}
                            header="Notify on"
                        />
                        {selectedAlarmNotify === 1 &&
                            <PhoneInput
                                inputProps={{
                                    maxLength: 16,
                                }}
                                inputStyle={{ width: "100%" }}
                                countryCodeEditable={false}
                                country={'bg'}
                                onlyCountries={['bg']}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e)}
                            />
                        }
                        {selectedAlarmNotify === 2 && <EmailInput emails={emails} setEmails={setEmails} />}
                        <VStack spacing={4} align="stretch" p={4} borderRadius="lg" borderWidth="1px" borderColor="gray.200">
                            <HStack justify="space-between">
                                <Text fontWeight="medium" userSelect="none">
                                    24/7 (The alarm will be active every day)
                                </Text>
                                <Switch
                                    isChecked={isChecked}
                                    onChange={() => setIsChecked(!isChecked)}
                                    size="lg"
                                    colorScheme="blue"
                                />
                            </HStack>

                            {!isChecked && <Divider />}

                            {!isChecked && (
                                <HStack spacing={4} justify="center">
                                    <Flex direction="column" align="center">
                                        <Text fontSize="sm" mb={1}>From</Text>
                                        <Input
                                            type="time"
                                            name="from"
                                            value={timeRange.from}
                                            onChange={handleTimeChange}
                                            onClick={handleInputClick}
                                            size="lg"
                                            borderColor={timeRange.from ? "gray.300" : "red.500"}
                                            focusBorderColor="blue.500"
                                            _hover={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Flex>

                                    <Flex align="center" fontWeight="bold" fontSize="lg">
                                        -
                                    </Flex>

                                    <Flex direction="column" align="center">
                                        <Text fontSize="sm" mb={1}>To</Text>
                                        <Input
                                            type="time"
                                            name="to"
                                            value={timeRange.to}
                                            onChange={handleTimeChange}
                                            onClick={handleInputClick}
                                            size="lg"
                                            borderColor={timeRange.to ? "gray.300" : "red.500"}
                                            focusBorderColor="blue.500"
                                            _hover={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Flex>
                                </HStack>
                            )}
                        </VStack>
                    </>
                )}
            </Flex>
        </CustomModal>
    )
}