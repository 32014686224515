import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Tooltip,
  Text,
  VStack,
  Skeleton,
  Button,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SelectableGroup } from 'react-selectable-fast'
import { useGetDeviceHeatMapQuery } from '../api/analysisApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { TSelectableItem } from 'react-selectable-fast/lib/Selectable.types'
import Grid from '../components/Selectable/Grid'
import { ArrowBackIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'
import Header from '../../../app/components/Header'
import AnalysisHomeForm from '../components/AnalysisHomeForm'
import { addCommasToNumber } from '../../../utils/helpers'
import DevicesBreakdown from '../components/DevicesBreakdown'
import { Analysis, SelectableItem } from '../api/types'
import { CURRENT_USAGE_POLLING_INTERVAL, ENERGY_GENERATOR, ENERGY_PLANT_LOGGER, KILOWATT, KILOWATT_HOUR } from '../../../utils/consts'
import {
  consumptionColorsArray,
  HEAT_MAP_KEY_CONSUMPTION,
  HEAT_MAP_KEY_GENERATED_POWER,
  photovoltaicsColorsArray,
} from '../utils'
import { useGetCurrentLocationsDataQuery } from '../../dashboard/api/dasboardApi'
import Flippable from '../../dashboard/components/Flippable'

interface SelectableProps {
  item: number
  hour: number
  consumption: number
  day: string
  idx: number
}

function isGenerator(category: string) {
  return category === ENERGY_GENERATOR || category === ENERGY_PLANT_LOGGER
}

const groupByDay = (heatMap: Analysis.Response['heat_map']) => {
  let currentHour = -1
  let latestFormattedDate = ""
  return heatMap?.reduce((groups, entry) => {
    const localDate = new Date(entry.time)
    const hour = localDate?.getHours()
    const formattedDate = localDate.toLocaleDateString("en-CA").replace(/-/g, '/')

    if (currentHour === hour) {
      const newEntry = {
        ...groups[latestFormattedDate][hour],
        avg_consumption: groups[latestFormattedDate][hour]['avg_consumption'] + entry?.avg_consumption,
        avg_generated_power: groups[latestFormattedDate][hour]['avg_generated_power'] + entry?.avg_generated_power,
      }
      groups[latestFormattedDate][hour] = newEntry
    } else {
      if (!groups[formattedDate]) {
        groups[formattedDate] = []
      }
      groups[formattedDate]?.push(entry)
    }
    currentHour = hour
    latestFormattedDate = formattedDate

    return groups;
  }, {} as Record<string, Analysis.Response['heat_map']>)
}

const AnimatedHeading = motion(Heading)
export const AnalysisData: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [heatMapKey, setHeatMapKey] = useState('avg_consumption')
  const [isBreakdownLoading, setIsBreakdownLoading] = useState(false)

  const selectableGridDimentions = {
    width:
      (((parseInt(searchParams.get('to_hour') || '24') -
        parseInt(searchParams.get('from_hour') || '0')) /
        2) *
        24 +
        25) *
      4,
    height:
      (dayjs(searchParams.get('to_date')).diff(searchParams.get('from_date'), 'days') *
        6 +
        25) *
      4,
  }

  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const deviceId = searchParams.get('device')
  const { data: analysisData, isSuccess: AnalysisDataSuccess } = useGetDeviceHeatMapQuery(
    {
      from_date: searchParams.get('from_date') || '',
      to_date: searchParams.get('to_date') || '',
      device: deviceId,
      location: searchParams.get('location'),
    },
  )

  const { data: currentLocationsData = [] } = useGetCurrentLocationsDataQuery(
    {
      locationIds: [Number(searchParams.get('location'))],
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
    },
  )

  useEffect(() => {
    if (isGenerator(analysisData?.category)) {
      setHeatMapKey(HEAT_MAP_KEY_GENERATED_POWER)
    } else {
      setHeatMapKey(HEAT_MAP_KEY_CONSUMPTION)
    }
  }, [analysisData])

  const colorsArray =
    heatMapKey === HEAT_MAP_KEY_CONSUMPTION
      ? consumptionColorsArray
      : photovoltaicsColorsArray

  const consumptionRates = useMemo(() => {
    if (analysisData) {

      let maxConsumption
      let minConsumption

      const consumptions: number[] = []

      analysisData?.heat_map?.forEach((item: any) => {
        if (item[heatMapKey] !== undefined) {
          consumptions?.push(item[heatMapKey]);
        }
      })
      maxConsumption = Math.max(...consumptions)

      const color1 = maxConsumption
      const color2 = color1 - (maxConsumption * 10) / 100
      const color3 = color2 - (maxConsumption * 10) / 100
      const color4 = color3 - (maxConsumption * 10) / 100
      const color5 = color4 - (maxConsumption * 10) / 100
      const color6 = color5 - (maxConsumption * 10) / 100
      const color7 = color6 - (maxConsumption * 10) / 100
      const color8 = color7 - (maxConsumption * 10) / 100
      const color9 = color8 - (maxConsumption * 10) / 100

      const color10 = minConsumption || 0

      return {
        color1,
        color2,
        color3,
        color4,
        color5,
        color6,
        color7,
        color8,
        color9,
        color10,
      }
    }
    return {}
  }, [analysisData, heatMapKey])

  const navigate = useNavigate()

  const [isSelecting, setIsSelecting] = useState<boolean>(false)
  // const [sliderValue, setSliderValue] = useState(0);
  // const sliderLabelStyles = {
  //   mt: "2",
  //   ml: "-2.5",
  //   fontSize: "sm",
  // };

  const [selectedItems, setSelectedItems] = useState<
    Array<
      TSelectableItem & {
        props: { item: SelectableProps }
      }
    >
  >([])

  interface TouchHour {
    hour: number
    idx: number
  }

  const [touchedHours, setTouchedHours] = useState<TouchHour[]>([])

  const selectedHours: SelectableItem[] = useMemo(
    () =>
      // const isTouchedContains = selectedItems.filter(item => touchedHours.current?.includes(item.props.hours))
      selectedItems.reduce<SelectableItem[]>(
        (prev, current) => prev.concat(current.props.item),
        [],
      ),
    [selectedItems],
  )

  const calculationsHours: SelectableItem[] = useMemo(() => {
    return selectedHours.filter(
      ({ idx, hour }) =>
        !touchedHours.some(
          ({ idx: delIdx, hour: delHour }) => idx === delIdx && hour === delHour,
        ),
    )
  }, [touchedHours, selectedHours])

  const totalSelectedConsumption = useMemo(
    () =>
      calculationsHours.reduce<number>((prev, current) => prev + current.consumption, 0),
    [calculationsHours],
  )

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    touchedHours.forEach(({ idx }) => {
      if (!calculationsHours.some(({ idx: calcIndex }) => idx === calcIndex)) {
        setTouchedHours([])
        unselect(idx)
      }
    })
  }, [calculationsHours, touchedHours])

  const selectableGroup = useRef<SelectableGroup | null>(null)
  const unselect = (idx: number) => {
    const selectedItems = selectableGroup.current?.selectedItems as Set<
      TSelectableItem & {
        props: { item: SelectableProps }
      }
    >
    selectedItems?.forEach((item) => {
      if (item.props.item.idx === idx) {
        selectedItems.delete(item)
        item.setState({ isSelected: false })
        setSelectedItems(Array.from(selectedItems))
      }
    })
  }

  const maxHours = useMemo(() => {
    if (!analysisData) return null

    const dayHourMap: Record<string, { date: Date; currentHour: number }[]> = {}
    let currentHour = -1
    analysisData.heat_map.forEach((value: any) => {
      const dateObj = new Date(value["time"])
      const dateKey = dateObj.toLocaleDateString("en-US")
      const hours = dateObj.getHours()

      if (!dayHourMap[dateKey]) {
        dayHourMap[dateKey] = []
      }

      if (currentHour !== hours) {
        dayHourMap[dateKey]?.push({ date: dateObj, currentHour: hours })
      }

      currentHour = hours
    })

    let maxDay = { day: null as Date | null, entries: [] as { date: Date; currentHour: number }[] }
    for (const [_, value] of Object.entries(dayHourMap)) {
      if (value.length > maxDay.entries.length) {
        maxDay = { day: value[0].date, entries: value }
      }
    }

    return maxDay;
  }, [analysisData])

  const groupedData = groupByDay(analysisData?.heat_map)

  return (
    <>
      <Header>
        <Heading as="h2" fontSize="2xl" fontWeight="bold">
          <Stack direction="row">
            <ArrowBackIcon
              _hover={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(-1)
              }}
            />
            <Text>Data Analysis</Text>
          </Stack>
        </Heading>
      </Header>
      <AnalysisHomeForm dataAnalysisPage />

      <Box
        py={10}
        px={6}
        mt={5}
        mb={20}
        h="full"
        w="full"
        position="relative"
        boxShadow="lg"
        bgColor="white"
        rounded="lg"
        borderWidth={1}
        sx={{
          '.selectable-selectbox': {
            zIndex: 9000,
            position: 'absolute',
            cursor: 'default',
            background: 'none',
            border: '1px dashed grey',
          },
        }}
      >
        <Skeleton isLoaded={AnalysisDataSuccess}>
          <Box w="100%" h="100%">
            {analysisData?.heat_map.length > 0 ? (
              <>
                <HStack spacing={{ base: 4 }} w="100%" justifyContent={{ base: 'space-between' }}>
                  <Flex direction="column">
                    <Text fontSize="sm" fontWeight="bold" color="text.light">
                      {deviceId ? 'Device' : ''}
                    </Text>
                    <Heading
                      as="h2"
                      fontSize={{ base: 'lg', md: '2xl', lg: '3xl' }}
                      fontWeight="bold"
                    >
                      <Flex direction="column">
                        {deviceId ? analysisData?.title : 'Total consumption'}
                        <Text
                          fontSize={{ base: 'xs', md: 'sm', lg: 'md' }}
                          display="inline-block"
                          fontWeight="normal"
                        >
                          {analysisData?.location?.title ? `(${analysisData?.location?.title.trim()})` : ''}
                        </Text>
                      </Flex>
                    </Heading>
                  </Flex>
                  {isDesktop && (
                    <Center height={20}>
                      <Divider orientation="vertical" height="full" />
                    </Center>
                  )}
                  <Flex gap="20px">
                    <Flex direction="column">
                      <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                        Selected time slot consumption:
                      </Heading>
                      <AnimatedHeading
                        as="h3"
                        key={totalSelectedConsumption}
                        minH="30px"
                        animate={{
                          fontSize: ['16px', '24px', '16px'],
                          transition: { duration: 1 },
                        }}
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight={{ base: 'normal' }}
                        mt={3}
                      >
                        {totalSelectedConsumption
                          ? `${addCommasToNumber(totalSelectedConsumption)} ${KILOWATT_HOUR}`
                          : 'N/A'}
                      </AnimatedHeading>
                    </Flex>
                    <>
                      <Flex direction="column">
                        <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                          Current Usage
                        </Heading>
                        <Flex gap="2" align="center">
                          <Flippable
                            value={
                              <Text color="text.dark" fontSize="20px" fontWeight="700">
                                {addCommasToNumber(
                                  currentLocationsData[searchParams.get('location')]?.consumption || 0,
                                )}
                              </Text>
                            }
                            unit={KILOWATT}
                            topMargin={2}
                          />
                        </Flex>
                      </Flex>
                      <Flex direction="column">
                        <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                          Carbon Intesity
                        </Heading>
                        <Heading
                          as="h3"
                          fontSize={{ base: 'sm', md: 'md' }}
                          fontWeight={{ base: 'normal' }}
                          mt={3}
                        >
                          {addCommasToNumber(analysisData?.location?.carbon_intensity || 24)} g
                        </Heading>
                      </Flex>
                    </>
                    <Flex direction="column">
                      <Heading as="h4" fontSize={{ base: 'xs', md: 'sm' }} color="text.light">
                        {isGenerator(analysisData?.category)
                          ? 'Total Produced energy for the period'
                          : 'Total Consumption for the period'}
                      </Heading>
                      <Heading
                        as="h3"
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight={{ base: 'normal' }}
                        mt={3}
                      >
                        {addCommasToNumber(
                          isGenerator(analysisData?.category)
                            ? analysisData?.location?.total_generated_power || 0
                            : analysisData?.location?.total_consumption || 0,
                        )}
                        {KILOWATT_HOUR}
                      </Heading>
                    </Flex>
                  </Flex>
                </HStack>
                <Stack
                  direction={isDesktop ? 'row' : 'column'}
                  mt={10}
                  mb={10}
                  spacing={12}
                  width="100%"
                  overflowX="hidden"
                  alignItems={isDesktop ? 'start' : 'center'}
                >
                  <Stack
                    direction="row"
                    w="100%"
                    overflowX="scroll"
                    pb="40px"
                    width="100%"
                    spacing={4}
                    alignItems="stretch"
                  >
                    <Flex
                      direction="column"
                    // minW={840}
                    >
                      <Flex minH="40px" mt="10px">
                        <Flex direction="column" justifyContent="space-around">
                          <Heading
                            as="h5"
                            fontSize="sm"
                            fontWeight="bold"
                            color="text.light"
                            alignSelf="flex-start"
                          >
                            Infographic
                          </Heading>
                        </Flex>
                        {selectedItems.length > 0 ? (
                          <Flex marginLeft="10px" width="320px" justifyContent="space-between">
                            <Tooltip
                              label={`You can press "Esc" key to clear selection`}
                              placement="top"
                              hasArrow
                            >
                              <Button
                                maxW="280px"
                                onClick={() => {
                                  selectableGroup?.current?.clearSelection()
                                }}
                              >
                                Clear selection
                              </Button>
                            </Tooltip>
                            {analysisData?.children.length ? (
                              <Button isLoading={isBreakdownLoading} maxW="280px" onClick={onOpen}>
                                Show Breakdown
                              </Button>
                            ) : null}
                          </Flex>
                        ) : null}
                      </Flex>
                      <Box zIndex={1} mt={8} hidden={!AnalysisDataSuccess}>
                        {AnalysisDataSuccess && (
                          <HStack spacing={2}>
                            <VStack spacing={0}>
                              {Object.entries(groupedData).map(([date, _], idx) => (
                                <Box
                                  key={idx}
                                  className="not-selectable"
                                  h={6}
                                  whiteSpace="nowrap"
                                  fontSize="sm"
                                  color="text.light"
                                >
                                  {date}
                                </Box>
                              ))}
                            </VStack>
                            {/* @ts-ignore */}
                            <SelectableGroup
                              className={
                                isSelecting ? 'selecting selectBox' : 'selection selectBox'
                              }
                              clickClassName="tick"
                              enableDeselect={true}
                              tolerance={0}
                              deselectOnEsc={true}
                              allowClickWithoutSelected={true}
                              duringSelection={() => setIsSelecting(true)}
                              onSelectionFinish={(selectedItems: any) => {
                                setIsSelecting(false)
                                setSelectedItems(selectedItems)
                              }}
                              ignoreList={['.not-selectable']}
                              ref={selectableGroup}
                            >
                              <Grid
                                items={groupedData}
                                pointerEvents={isSelecting ? 'none' : 'auto'}
                                consumptionRates={consumptionRates}
                                heatMapKey={heatMapKey}
                              />
                            </SelectableGroup>
                          </HStack>
                        )}
                      </Box>
                      <HStack spacing={0} mt={1} hidden={!AnalysisDataSuccess}>
                        <Box fontSize="sm" w={'88px'} h={5} mt={2}></Box>
                        {maxHours &&
                          maxHours?.entries.map((hours) => {
                            return (
                              <Box
                                key={hours.currentHour}
                                fontSize="sm"
                                w={12}
                                textAlign="center"
                                color="text.light"
                              >
                                {`${hours.date.getHours()}h`}
                              </Box>
                            )
                          })}
                      </HStack>
                    </Flex>
                    <Flex direction="column" pb={5}>
                      <Heading as="h5" fontSize="sm" fontWeight="bold" color="text.light">
                        Scale
                      </Heading>
                      <HStack alignItems="stretch" h="full" mt={8}>
                        <VStack spacing={1}>
                          {colorsArray.map((item, idx) => (
                            <Box key={idx} w={3} bgColor={item.color} h={item.height}></Box>
                          ))}
                        </VStack>
                        <VStack justify="space-between">
                          {Array.from([
                            consumptionRates?.color1,
                            consumptionRates?.color3,
                            consumptionRates?.color5,
                            consumptionRates?.color8,
                            consumptionRates?.color10,
                          ]).map((item, idx) => (
                            <Box key={idx} fontSize="sm" color="text.light">
                              {typeof item === 'number'
                                ? `${+parseFloat(item.toFixed(1))} ${KILOWATT_HOUR}`
                                : null}
                            </Box>
                          ))}
                        </VStack>
                      </HStack>
                    </Flex>
                  </Stack>
                  {selectedItems.length ? (
                    <Stack
                      direction={isDesktop ? 'column' : 'row'}
                      fontSize="xs"
                      color="text.intermediate"
                      fontWeight="bold"
                      spacing={5}
                      minH={isDesktop ? 'lg' : 'auto'}
                      h={isDesktop ? `${selectableGridDimentions.height}px` : 'auto'}
                      justify="flex-start"
                      divider={<Divider orientation={isDesktop ? 'horizontal' : 'vertical'} />}
                      w={isDesktop ? 44 : 'auto'}
                    ></Stack>
                  ) : null}
                  {isOpen ? (
                    <DevicesBreakdown
                      device={deviceId}
                      selectedHours={selectedHours}
                      onClose={onClose}
                      isOpen={isOpen}
                      setIsLoading={setIsBreakdownLoading}
                    />
                  ) : null}
                </Stack>
              </>
            ) : "No data found"}
          </Box>
        </Skeleton>
      </Box>
    </>
  )
}
