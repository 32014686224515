import { Flex, Text, Box, Divider, Grid, GridItem } from '@chakra-ui/react'
import React from 'react'
import {
  deviceGroupSumByProperty,
  formatDate,
  addCommasToNumber,
  calculatePercentage,
  getLocationsPercentages,
} from '../../../../../utils/helpers'
import { Card } from './CardComponent'
import CloudIcon from '../../../../../assets/icons/CloudIcon'
import { ConsumedEnergyIcon } from '../../../../../assets/icons/ConsumedEnergy'
import ProducedEnergyIcon from '../../../../../assets/icons/ProducedEnergyIcon'
import {
  KILOWATT_HOUR,
  CARBON_INTENSITY,
  MONETARY_VALUE,
  CARBON_FOOTPRINT_KG,
} from '../../../../../utils/consts'
import CostForConsumptionIcon from '../../../../../assets/icons/CostConsumptionIcon'
import GeneratedIncomeIcon from '../../../../../assets/icons/GeneretedIncomeIcon'
import CarbonIntensityIcon from '../../../../../assets/icons/CarbonIntensity'
import { EnergyConsumptionReport, IGroup, IDevice, Location } from '../../../api/types'
import { GroupsConsumption } from './GroupsConsumption'
import PercentageChange from './PercentageChange'

type Props = {
  startDate: Date
  endDate: Date
  locations: EnergyConsumptionReport
}

type GroupDeviceProps = {
  group: IGroup
  device: IDevice
}

function getGroupDevicePercentage(props: GroupDeviceProps) {
  const { group, device } = props
  const property = isConsumptionGroup(group)
    ? 'device_consumption'
    : 'device_generated_power'
  const groupTotalConsumption = group.devices.reduce(
    (sum, device) => sum + device[property],
    0,
  )
  return calculatePercentage(device[property], groupTotalConsumption, 2)
}

function getGroupTotalConsumption(group: IGroup, property: string) {
  const excludeIsMain = group.devices.filter((device) => !device.device_is_main)
  const totalConsumption = deviceGroupSumByProperty(excludeIsMain, property)
  return addCommasToNumber(totalConsumption)
}

function isConsumptionGroup(group: IGroup) {
  return deviceGroupSumByProperty(group.devices, 'device_consumption') > 0
}

function getGroupsPercentages(group: IGroup, current: string, previous: string) {
  const currentValue = group?.devices.reduce((sum, device) => sum + device[current], 0)
  const previousValue = group?.devices.reduce((sum, device) => sum + device[previous], 0)

  if (currentValue > 0 && previousValue > 0) {
    const percentageChange = ((previousValue - currentValue) / previousValue) * 100
    return Number(percentageChange.toFixed(2))
  }
  return 0
}

export const EnergyReportBody = (props: Props) => {
  const { startDate, endDate, locations } = props

  return (
    <>
      {locations?.map((location, index) => {
        return (
          <Flex
            key={`${index}-${location.location_id}`}
            id="reportBody"
            width="100%"
            direction="column"
            px={10}
            gap="15px"
            mb="30px"
          >
            <Flex width="100%" alignItems="center" justifyContent="space-between">
              <Text fontWeight={700} fontSize="15px" fontFamily="Inter">
                Energy Consumption Report
              </Text>
              <Text fontSize="15px" fontWeight={400} color="#484848" fontFamily="Inter">
                {`${formatDate(startDate)} - ${formatDate(endDate)}`}
              </Text>
            </Flex>
            <Box
              height="100%"
              rounded="lg"
              bg="container.background.gray"
              px="20px"
              py="20px"
            >
              <Text
                alignItems="center"
                fontWeight={700}
                fontSize="18px"
                fontFamily="Inter"
                color="#157FEE"
              >
                {location.location_name}
              </Text>
              <GroupsConsumption location={location} />
            </Box>
            <Flex direction="column" fontSize={20} fontWeight={700} gap={5}>
              <Flex direction="column" gap={1}>
                <Flex gap={3}>
                  <Card
                    width="100%"
                    title="Total consumed energy"
                    measurementType={KILOWATT_HOUR}
                    value={location.location_total_consumption}
                    percentageChange={getLocationsPercentages(
                      location,
                      'location_total_consumption',
                      'previous_location_total_consumption',
                    )}
                  >
                    <ConsumedEnergyIcon boxSize="18px" color="text.secondary" />
                  </Card>
                  <Card
                    width="100%"
                    title="Produced energy"
                    measurementType={KILOWATT_HOUR}
                    value={location.location_total_generated_power}
                    percentageChange={getLocationsPercentages(
                      location,
                      'location_total_generated_power',
                      'previous_location_total_generated_power',
                    )}
                  >
                    <ProducedEnergyIcon boxSize="18px" color="text.secondary" />
                  </Card>
                  <Card
                    width="100%"
                    title="Carbon footprint"
                    measurementType={CARBON_FOOTPRINT_KG}
                    value={location.location_total_carbon_footprint}
                    percentageChange={getLocationsPercentages(
                      location,
                      'location_total_carbon_footprint',
                      'previous_location_total_carbon_footprint',
                    )}
                  >
                    <CloudIcon boxSize="18px" color="text.secondary" />
                  </Card>
                </Flex>
                <Flex gap={3}>
                  <Card
                    width="100%"
                    title="Cost for consumption"
                    measurementType={MONETARY_VALUE}
                    value={location.location_total_consumption_cost}
                    percentageChange={getLocationsPercentages(
                      location,
                      'location_total_consumption_cost',
                      'previous_location_total_consumption_cost',
                    )}
                  >
                    <CostForConsumptionIcon boxSize="18px" color="text.secondary" />
                  </Card>
                  <Card
                    width="100%"
                    title="Generated income"
                    measurementType={MONETARY_VALUE}
                    value={location.location_total_generated_power_cost}
                    percentageChange={getLocationsPercentages(
                      location,
                      'location_total_generated_power_cost',
                      'previous_location_total_generated_power_cost',
                    )}
                  >
                    <GeneratedIncomeIcon boxSize="18px" color="text.secondary" />
                  </Card>
                  <Card
                    width="100%"
                    title="Carbon intensity"
                    measurementType={CARBON_INTENSITY}
                    value={location.location_carbon_intensity}
                  >
                    <CarbonIntensityIcon boxSize="18px" color="text.secondary" />
                  </Card>
                </Flex>
              </Flex>
            </Flex>
            {location.groups?.map((group, index) => {
              return (
                <Box key={`${index}-${group.group_name}`} rounded="lg" bg="container.background.gray" px="20px" py="30px">
                  <Flex justifyContent="space-between" alignItems="center" mb={10}>
                    <Text fontWeight={700} fontSize="18px" color="#484848">
                      {group.group_name}
                    </Text>
                    <Flex gap={20}>
                      <Flex direction="column">
                        <Text
                          fontWeight={400}
                          fontSize="12px"
                          color="#707070"
                          mb="10px"
                          fontFamily="Inter"
                        >
                          {isConsumptionGroup(group)
                            ? 'Total consumed energy'
                            : 'Total generated power'}
                        </Text>
                        <Flex gap={3}>
                          <Text fontWeight={700} fontSize="14px" color="#484848">
                            {isConsumptionGroup(group)
                              ? addCommasToNumber(group.group_consumption)
                              : addCommasToNumber(group.group_generated_energy)}{" "}
                            <span style={{ fontWeight: 400 }}>kWh</span>
                          </Text>
                          {isConsumptionGroup(group) ? (
                            <PercentageChange
                              percentageChange={getGroupsPercentages(
                                group,
                                'device_consumption',
                                'previous_device_consumption',
                              )}
                              iconBoxSize={3}
                              percentageFontSize={12}
                            />
                          ) : (
                            <PercentageChange
                              percentageChange={getGroupsPercentages(
                                group,
                                'device_generated_power',
                                'previous_device_generated_power',
                              )}
                              iconBoxSize={3}
                              percentageFontSize={12}
                            />
                          )}
                        </Flex>
                      </Flex>
                      <Flex direction="column">
                        <Text fontWeight={400} fontSize="12px" color="#707070" mb="10px">
                          Carbon Footprint
                        </Text>
                        <Flex gap={3}>
                          <Text fontWeight={700} fontSize="14px" color="#484848">
                            {addCommasToNumber(group.group_carbon_footprint)}{" "}
                            <span style={{ fontWeight: 400 }}>{CARBON_FOOTPRINT_KG}</span>
                          </Text>
                          <PercentageChange
                            percentageChange={getGroupsPercentages(
                              group,
                              'device_carbon_footprint',
                              'previous_device_carbon_footprint',
                            )}
                            iconBoxSize={3}
                            percentageFontSize={12}
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  {group?.devices.map((device, index) => {
                    return (
                      <Flex key={`${index}-${device.device_id}`}direction="column" mb={2}>
                        <Flex mb={2} alignItems="center" justifyContent="space-between">
                          <Text
                            fontWeight={device?.is_repeated ? "bold" : 400} 
                            fontSize="14px"
                            color={"#484848"}
                            fontFamily="Inter"
                          >
                            {device.device_name}
                          </Text>
                          <Flex alignItems="center">
                            <Text fontWeight={700} color="#707070">
                              {isConsumptionGroup(group)
                                ? addCommasToNumber(device.device_consumption)
                                : addCommasToNumber(device.device_generated_power)}{' '}
                              kWh
                            </Text>
                            <Box
                              className="dot"
                              borderRadius="50%"
                              fontWeight={700}
                              w="5px"
                              h="5px"
                              mx={2}
                              bg="#707070"
                            />
                            <Text fontWeight={700} color="#707070">
                              {getGroupDevicePercentage({
                                group,
                                device,
                              })}
                              %
                            </Text>
                          </Flex>
                        </Flex>
                       
                        {renderDeviceChildrenRecursive(device?.children, group, isConsumptionGroup)}

                        <Divider orientation="horizontal" />
                      </Flex>
                    )
                  })}
                </Box>
              )
            })}
          </Flex>
        )
      })}
    </>
  )
}

function renderDeviceChildrenRecursive(children, group, isConsumptionGroup, marginLeft=0) {
  if (!children || children.length === 0) return null
    marginLeft = 4
  return (
    <>
      {children.map((child, index) => (
        <Grid templateColumns="repeat(2, 1fr)" gap={2} key={`${index}-${child.device_id}`} marginLeft={marginLeft}>
          <GridItem colSpan={1}>
            <Text   
                fontWeight={child?.is_repeated ? "bold" : 400} 
                fontSize="14px" 
                color={"#484848"}
                fontFamily="Inter">
              {child.device_name}
            </Text>
          </GridItem>
          <GridItem colSpan={1} justifyItems="flex-end">
            <Flex alignItems="center">
              <Text fontWeight={700} color="#707070">
                {isConsumptionGroup(group)
                  ? addCommasToNumber(child.device_consumption)
                  : addCommasToNumber(child.device_generated_power)}{' '}
                kWh
              </Text>
              <Box
                className="dot"
                borderRadius="50%"
                fontWeight={700}
                w="5px"
                h="5px"
                mx={2}
                bg="#707070"
              />
              <Text fontWeight={700} color="#707070">
                {getGroupDevicePercentage({
                  group,
                  device: child,
                })}
                %
              </Text>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
          {child.children &&
            renderDeviceChildrenRecursive(child.children, group, isConsumptionGroup, marginLeft)}
        </GridItem>
        </Grid>
      ))}
    </>
  )
}
  