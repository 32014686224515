import React, { useState } from 'react'
import {
  Text,
  Flex,
  Checkbox,
  Grid,
  GridItem,
  Divider,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
} from '@chakra-ui/react'
import DeviceDetailsButton from '../../dashboard/widgets/DeviceDetailsButton'
import ClimateDeviceData from '../../dashboard/components/ClimateDeviceData'

export const Climate: React.FC<{
  climateControls: any[]
  locations: any[]
  isLoading: boolean
}> = ({ climateControls, isLoading, locations }) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleCheckboxChange = (device) => {
    setSelectedOptions((prev) =>
      prev.find((d) => d.id === device.id)
        ? prev.filter((item) => item.id !== device.id)
        : [...prev, device],
    )
  }

  const handleSelectAllChange = () => {
    if (selectedOptions.length === climateControls.length) {
      setSelectedOptions([])
    } else {
      setSelectedOptions(climateControls.map((row) => ({ id: row.id, title: row.title })))
    }
  }

  const isAllSelected = selectedOptions.length === climateControls?.length

  const climateControlsView = climateControls
    .map((cc) => ({
      ...cc,
      locationName: locations.find((l) => l.id === cc.location_id).title,
    }))
    .reduce((acc, cc) => {
      if (!acc[cc.locationName]) {
        acc[cc.locationName] = []
      }

      acc[cc.locationName].push(cc)
      return acc
    }, {})

  if (isLoading) {
    return <Spinner size="xl" />
  }

  return (
    <Flex
      alignItems="flex-end"
      flexWrap="wrap-reverse"
      w="full"
      gap={6}
      bg="white"
      rounded="xl"
    >
      <Grid templateColumns="repeat(2, 1fr)" gap={4} p={5} w="full">
        <GridItem colSpan={2}>
          <Flex alignItems="center">
            <Checkbox
              isChecked={isAllSelected}
              iconSize="3rem"
              marginRight="5"
              onChange={() => handleSelectAllChange()}
            />
            <Text ml={2} color="text.secondary">
              {isAllSelected ? 'Deselect All' : 'Select all'}
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Divider />
        </GridItem>

        {Object.keys(climateControlsView).map((key, index) => (
          <React.Fragment key={key}>
            <GridItem
              colSpan={2}
              ml="1"
              pl="1"
              borderLeft="2px solid"
              borderColor="#edf1f6"
            >
              <Text fontSize="14px" fontWeight={700}>
                {key}
              </Text>
              {climateControlsView[key]?.map((device) => (
                <>
                  <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                    ml="4"
                    py="3"
                  >
                    <Flex alignItems="center">
                      <Checkbox
                        iconSize="3rem"
                        marginRight="5"
                        isChecked={
                          selectedOptions.find((option) => option.id === device.id) ||
                          false
                        }
                        onChange={() =>
                          handleCheckboxChange({
                            id: device.id,
                            title: device.title,
                          })
                        }
                      />
                      <Text ml={2}>{device.title}</Text>
                    </Flex>
                    <DeviceDetailsButton
                      id={device.id}
                      isFetching={isLoading}
                      queryParams={null}
                    />
                  </Flex>
                  <Divider borderColor="#edf1f6" w="full" my="1" ml="4" />
                </>
              ))}
            </GridItem>
          </React.Fragment>
        ))}

        <GridItem colSpan={2}>
          <Button disabled={selectedOptions.length === 0} onClick={onOpen}>
            Update all
          </Button>
        </GridItem>
      </Grid>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Devices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ClimateDeviceData
              deviceId={selectedOptions[0]?.id}
              isEnabled={true}
              devices={selectedOptions}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}