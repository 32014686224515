import React from 'react'
import { Spinner, Grid, Flex, Text } from '@chakra-ui/react'
import Card from './Card'
import { useGetCurrentLocationsDataQuery } from '../../dashboard/api/dasboardApi'
import { CURRENT_USAGE_POLLING_INTERVAL } from '../../../utils/consts'
import { addCommasToNumber } from '../../../utils/helpers'
import { DashboardOverviewLocation } from '../../dashboard/types'
import { LocationsIcon } from '../../../assets/icons/Locations'

interface LocationProps {
  filteredLocations: DashboardOverviewLocation[]
  isLoading: boolean
  setIndex: React.Dispatch<React.SetStateAction<number>>,
  setFilteredLocations: React.Dispatch<any>
}

export default function Locations(props: LocationProps) {
  const { isLoading, filteredLocations, setIndex, setFilteredLocations } = props

  const ids = filteredLocations?.map((location) => location.id) || []

  const { data: currentLocationsData = [] } = useGetCurrentLocationsDataQuery(
    {
      locationIds: ids,
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
      skip: ids.length === 0,
    },
  )

  if (isLoading) {
    return <Spinner size="xl" />
  }

  const handleCardClick = (e: React.MouseEvent, id: number) => {
    e.preventDefault()
    setIndex(1)
    setFilteredLocations((prevLocations) =>
      prevLocations.filter((location) => location.id === id)
    )
  }


  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {filteredLocations?.map(({ title, id, energy_data, price_unit }) => (
        <Card title={title} id={id} key={id} onClick={handleCardClick} cardIcon={<LocationsIcon boxSize="24px" />}>
          <Flex direction="column">
            <Text>Current usage: {addCommasToNumber(
              Math.round(currentLocationsData[id]?.consumption || 0),
            )} {price_unit}</Text>
            <Text>Consumption: {addCommasToNumber(
              Math.round(energy_data.total_consumption),
            ) || 0} {price_unit}</Text>
          </Flex>
        </Card>
      ))}
    </Grid>
  )
}
