import { Box, Text } from '@chakra-ui/react'
import { useMemo } from 'react'

interface Props {
  type: 'Critical' | 'Minor' | 'Warning' | 'Major' | 'Info' | 'Multiple'
}

const colorMap = {
  Critical: '#E24654',
  Minor: '#FFE3D2',
  Warning: '#FFF7CA',
  Major: '#FDC8CC',
  Info: 'icon.strong',
  Multiple: '#A49EDC',
}

export const Label: React.FC<Props> = ({ type }) => {
  const color = useMemo(() => colorMap[type], [type])
  return (
    <Box
      p="4px"
      bg={color}
      borderRadius={8}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text
        color={type === 'Critical' || type === 'Info' ? 'white' : 'text.dark'}
        fontSize="12px"
        fontFamily="Inter"
        fontWeight="700"
        lineHeight="16px"
        letterSpacing="0.24px"
        wordBreak="break-word"
      >
        {type}
      </Text>
    </Box>
  )
}
