import { Box, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import Header from '../../../app/components/Header'

interface NotFoundProps {
    title: string,
    description: string,
    header?: boolean,
}

export default function NotFound(props: NotFoundProps) {
    const { title, description, header } = props

    return (
        <Box width="100%" height="100vh">
            {header ?? <Header title="Automations" />}
            <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                height="calc(100vh - 150px)"
            >
                <Text fontWeight="bold" fontSize="lg" mb={2}>{title}</Text>
                <Text color="gray.500">
                    {description}
                </Text>
            </Flex>
        </Box>
    )
}
