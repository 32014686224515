import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { Bookmark } from "../../../../assets/icons/Bookmark";
import { useReportFilterContext } from "../../../../context/ReportFilterContext";
import { useCreateRecurringReportMutation, useDeleteBookmarkMutation, useGetRecurringReportQuery } from "../../api/reportsApi";
import { ReportData } from "../../types";
import { formatDateString, generateTooltip } from "../../../../utils/helpers";
import { BOOKMARK_TEMPLATE_LIMIT, BOOKMARK_TOOLTIP, UNBOOKMARK_TOOLTIP } from "../../../../utils/consts";

type BookmarkReportProps = {
  disabled?: boolean;
  isDownloading?: boolean;
} & ReportData.RecurringReportParams;

export default function BookmarkReport(props: BookmarkReportProps) {
  const {
    disabled,
    startDate,
    endDate,
    locations,
    deviceGroups,
    deviceTypes,
    energyType,
    reportType,
    measurementTypes,
    devices,
    totalDetailed,
    ibexPrice,
    carbonIntensity,
    reportName,
    savedAs,
    filters_type,
    isDownloading,
  } = props;

  const locationsIds = locations?.map((x) => x.id.toString()).join(",")
  const groupsIds = deviceGroups?.map((x) => x.id.toString()).join(",")

  const recurringReportParams: ReportData.RecurringReportParams = {
    startDate: startDate,
    endDate: endDate,
    locations: locationsIds,
    deviceGroups: groupsIds,
    deviceTypes: deviceTypes,
    energyType: energyType,
    reportType: reportType,
    measurementTypes: measurementTypes,
    devices: devices,
    totalDetailed: totalDetailed,
    ibexPrice: ibexPrice,
    carbonIntensity: carbonIntensity,
    reportName: reportName,
    filters_type: filters_type,
    savedAs: savedAs,
  }

  const { data, refetch } = useGetRecurringReportQuery(recurringReportParams)
  const [createRecurringReport, { isLoading }] = useCreateRecurringReportMutation()
  const [isBookmarked, setIsBookmarked] = useState(false)
  const { bookmarkTemplateCount } = useReportFilterContext()
  const [bookmarkId, setBookmarkId] = useState(null)
  const [trigger, { isLoading: isDeleting }] = useDeleteBookmarkMutation()
  const helperText = isBookmarked ? UNBOOKMARK_TOOLTIP : BOOKMARK_TOOLTIP
  const tooltip = generateTooltip(bookmarkTemplateCount, BOOKMARK_TEMPLATE_LIMIT, helperText)

  useEffect(() => {
    if (data?.report_id) {
      setIsBookmarked(true)
      setBookmarkId(data?.report_id)
    } else {
      setIsBookmarked(false)
      setBookmarkId(null)
    }
    refetch()
  }, [data?.report_id, refetch])

  const handleUnselectBookmark = async () => {
    await trigger({ id: bookmarkId })
    setIsBookmarked(false)
    setBookmarkId(null)
    refetch()
    toast.success('Report has been successfully removed from bookmarks', {
      style: { padding: '10px 20px' },
    })
  }

  const onClick = () => {
    if (!isBookmarked) {
      createRecurringReport(recurringReportParams)
        .then((result: any) => {
          if (result?.data?.report_id) {
            refetch()
            toast.success('The report has been bookmarked successfully', {
              style: { padding: '10px 20px' },
            })
          } else {
            toast.info(result?.error?.data.message || 'Something went wrong', {
              style: { padding: '10px 5px' },
            })
          }
        })
        .catch((error: any) => {
          toast.error('An error occurred while saving the report.', {
            style: { padding: '10px 20px' },
          })
        })
    } else {
      handleUnselectBookmark()
    }
  }

  return (
    <>
      <Tooltip label={tooltip} hasArrow placement="top">
        <Box>
          <IconButton
            size="sm"
            disabled={disabled || bookmarkTemplateCount >= 20 || isDeleting || isDownloading}
            background="none"
            aria-label="bookmark"
            _focus={{ boxShadow: 'none' }}
            icon={<Bookmark boxSize="24px" color={isBookmarked ? "#157fee" : "white"} />}
            onClick={onClick}
            isLoading={isLoading}
          />
        </Box>
      </Tooltip>
    </>
  )
}