import { format } from 'date-fns'
import { Alarm } from '../features/alarms/api/types'
import { IDevice, Location } from '../features/reports/api/types'
import { REPORT_TYPES } from '../features/reports/pages/GenerateReport'
import { KILOWATT_HOUR, KILOWATT, ENERGY_PLANT_LOGGER, ENERGY_GENERATOR } from './consts'

export const mutatePriceUnit = (priceUnit: string) => {
  if (priceUnit === KILOWATT) {
    return KILOWATT_HOUR
  }
}

export const splitAndUppercase = (string: string, delimeter: string) =>
  string
    .split(delimeter)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

export const addCommasToNumber = (num: number | string) => {
  if (typeof num === 'string') {
    return num
  }

  if (!num) {
    return Number(0).toLocaleString()
  }

  return parseFloat(num.toFixed(2)).toLocaleString()
}

export const getLocationIndex = (data: any, locationId: string | null) => {
  return data.findIndex((item: any) => item.id.toString() === locationId) + 1
}

export const calculatePercentage = (
  part: number,
  whole: number,
  roundingIndex: number = 0,
): number => {
  if (whole === 0 || part === 0) {
    return 0
  }
  const result = Number(((part / whole) * 100).toFixed(roundingIndex))

  return result
}

const priceUnitMap = {
  kW: KILOWATT_HOUR,
  MW: 'mWh',
}

export const getPriceUnitMap = (priceUnit?: keyof typeof priceUnitMap) =>
  priceUnitMap[priceUnit || KILOWATT]

const DEVICE_STATUS = {
  null: 'N/A',
  undefined: 'N/A',
  0: 'Idle',
  1: 'On-Grid',
  2: 'On-Grid: self derating',
  3: 'On-Grid: Power limit',
  4: 'Planned outage',
  5: 'Power limit outage',
  6: 'Fault outage',
  7: 'Communication interrupt',
}

export type DeviceStatusKeys = keyof typeof DEVICE_STATUS

export const getDeviceStatus = (status: DeviceStatusKeys): string => DEVICE_STATUS[status]

export const getDeviceCurrentDataFieldsByCategory = (category) => {
  switch (category) {
    case ENERGY_PLANT_LOGGER:
      return ['generated_power', 'current', 'input_power', 'dc_current']
    case ENERGY_GENERATOR:
      return []
    default:
      return ['consumption']
  }
}

export const getSubdomain = () => window.location.hostname.split('.')[0]

export const METHODIA_SUBDOMAIN = 'methodia'

export const downloadFile = (href, filename) => {
  const link = document.createElement('a')
  link.href = href
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export function formatDate(date) {
  const options = { day: '2-digit', month: 'short', year: 'numeric' }
  return date?.toLocaleDateString('en-GB', options).replace(',', '')
}

export const deviceGroupSumByProperty = (devices: IDevice[], property: string) => {
  return devices.reduce((total, item) => total + (item[property] || 0), 0)
}

export const formatDateString = (date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getReportNameById = (id: number): string | undefined => {
  return REPORT_TYPES.find((report) => report.id === id)?.name
}

export const setTemplateCount = (
  setBookmarkTemplateCount: (value: number) => void,
  setRecurringTemplateCount: (value: number) => void,
  templateType: string,
  templateCount,
) => {
  switch (templateType) {
    case 'bookmark':
      setBookmarkTemplateCount(templateCount)
      break
    case 'recurring':
      setRecurringTemplateCount(templateCount)
      break
    default:
      break
  }
}

export const blobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1] || '')
      } else {
        reject(new Error('FileReader result is not a string'))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

export const generateTooltip = (templateCount, templateLimit, reportType) => {
  if (templateCount >= templateLimit) {
    return `You have reached the limit of saved samples (${templateLimit}). To save a new one, please remove an old sample.`
  } else {
    return `${reportType} Report`
  }
}

export const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '&::-webkit-scrollbar-track': {
    background: 'gray.200',
    borderRadius: '0 0.25em 0.25em 0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'blue.500',
    borderRadius: '0.25em',
    border: '2px solid transparent',
    backgroundClip: 'padding-box',
  },
}

export const calculateStepSize = (range) => {
  const roughStep = range / 10
  const magnitude = Math.pow(10, Math.floor(Math.log10(roughStep)))
  const niceStepSize = Math.ceil(roughStep / magnitude) * magnitude

  return niceStepSize
}

export function getGroupTotalValueCard(location: Location, current: string) {
  let total = 0
  location.groups.forEach((x) => (total += x[current]))
  return total
}

export function getGroupTotalValueCardPercentages(
  location: Location,
  current: string,
  previous: string,
) {
  const currentValue = getGroupTotalValueCard(location, current)
  const previousValue = getGroupTotalValueCard(location, previous)

  if (currentValue > 0 && previousValue > 0) {
    const percentageChange = ((previousValue - currentValue) / previousValue) * 100
    return Number(percentageChange.toFixed(2))
  }
  return 0
}

export function getLocationsPercentages(
  location: Location,
  current: string,
  previous: string,
) {
  const currentValue = location[current]
  const previousValue = location[previous]

  if (currentValue > 0 && previousValue > 0) {
    const percentageChange = ((previousValue - currentValue) / previousValue) * 100
    return Number(percentageChange.toFixed(2))
  }
  return 0
}

export function getDevices(alarm: Alarm) {
  if (alarm.device) {
    return alarm?.devices.filter((device) => device.id === alarm.device)
  }
  return alarm?.devices
}

export function getAlarmSeverity(alarm: Alarm, activeIndex) {
  const type =
    activeIndex < 2 && Array.isArray(alarm?.multistate_alarm?.multistate_fields)
      ? alarm.multistate_alarm.multistate_fields.find(
          (x) => Number(x.value) === Number(alarm?.value),
        )
      : alarm

  return type?.severity
}

export const formatAlarmDate = (date, placeholder = '-') => {
  try {
    return date ? format(date, 'yyyy-MM-dd HH:mm:ss') : placeholder
  } catch (error) {
    console.error('Error formatting date:', error)
    return placeholder
  }
}

export const getFilteredFields = (activeTab, multistateValue, fields) => {
  if (activeTab === 0 || activeTab === 1) {
    return fields.filter((x) => x.value === multistateValue)
  }
  return fields
}
