import { Flex, Text, Box } from '@chakra-ui/react'
import React from 'react'
import { IGroup, Location } from '../../../api/types'
import { calculatePercentage, deviceGroupSumByProperty, getLocationsPercentages } from '../../../../../utils/helpers'
import GroupInfo from './GroupInfo'

type Props = {
    location: Location
}

// Generate a dynamic blue color palette
function generateColors(count: number): string[] {
    const colors = [];
    const startHue = 200;
    const saturation = 70;
    const lightnessStep = 30 / count;

    for (let i = 0; i < count; i++) {
        const lightness = 50 + i * lightnessStep;
        const color = `hsl(${startHue}, ${saturation}%, ${lightness}%)`;
        colors.push(color);
    }
    return colors;
}

function isConsumptionGroup(group: IGroup, property: string) {
    return deviceGroupSumByProperty(group.devices, property) > 0
}

function getTopGroupsTotal(location: Location, deviceProperty: string, property: string) {
    const filteredGroups = location.groups.filter((group) => isConsumptionGroup(group, deviceProperty))

    if (filteredGroups.length === 0) {
        return []
    }

    const groupConsumptions = filteredGroups?.map(group => {
        const excludeIsMain = group.devices.filter((device) => !device.device_is_main)
        const total = excludeIsMain.reduce((sum, device) => sum + device[deviceProperty], 0);
        return {
            group_name: group.group_name,
            total: total,
        };
    });

    const sortedGroups = groupConsumptions?.sort((a, b) => b.total - a.total);

    const topGroups = sortedGroups?.slice(0, 4).map(group => ({
        group_name: group.group_name,
        total: group.total,
        percentage: calculatePercentage(group.total, location[property], 2),
    }));

    return topGroups;
}

export const GroupsConsumption = (props: Props) => {
    const { location } = props
    const topConsumptionGroups = getTopGroupsTotal(location, "device_consumption", "location_total_consumption")
    const consumptionGroupNames = topConsumptionGroups.map((group) => group.group_name)
    const consumptionGroupPercents = topConsumptionGroups.map((group) => group.percentage)

    // Generate dynamic colors based on the number of groups
    const consumptionDynamicColors = generateColors(consumptionGroupNames.length);
    const consumptionPercentageChange = getLocationsPercentages(location, "location_total_consumption", "previous_location_total_consumption")


    const topProducersGroups = getTopGroupsTotal(location, "device_generated_power", "location_total_generated_power")
    const producersGroupNames = topProducersGroups.map((group) => group.group_name)
    const producersGroupPercents = topProducersGroups.map((group) => group.percentage)

    // Generate dynamic colors based on the number of groups
    const producersDynamicColors = generateColors(producersGroupNames.length);
    const producersPercentageChange = getLocationsPercentages(location, "location_total_generated_power", "previous_location_total_generated_power")

    return (
        <Flex width="100%" pt={4} direction="column" gap="70px">
            {topConsumptionGroups.length > 0 && (
                <Box>
                    <Text mb="20px">Consumer groups:</Text>
                    <GroupInfo
                        dynamicColors={consumptionDynamicColors}
                        groupNames={consumptionGroupNames}
                        groupPercents={consumptionGroupPercents}
                        percentageChange={consumptionPercentageChange}
                        topGroups={topConsumptionGroups}
                        total={location.location_total_consumption}
                    />
                </Box>
            )}
            {topProducersGroups.length > 0 && (

                <Box>
                    <Text mb="20px">Generator groups:</Text>
                    <GroupInfo
                        dynamicColors={producersDynamicColors}
                        groupNames={producersGroupNames}
                        groupPercents={producersGroupPercents}
                        percentageChange={producersPercentageChange}
                        topGroups={topProducersGroups}
                        total={location.location_total_generated_power}
                    />
                </Box>
            )}
        </Flex>
    )
}