import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import fileIcon from '../../../../../assets/file_text.png'
import logoSvg from '../../../../../assets/logo.svg'
import checkIcon from '../../../../../assets/checkIcon.png'
import { ConsumedEnergyIcon } from '../../../../../assets/icons/ConsumedEnergy'
import ProducedEnergyIcon from '../../../../../assets/icons/ProducedEnergyIcon'
import { KILOWATT_HOUR, CARBON_FOOTPRINT_KG } from '../../../../../utils/consts'
import CloudIcon from '../../../../../assets/icons/CloudIcon'
import { addCommasToNumber, deviceGroupSumByProperty, formatDate } from '../../../../../utils/helpers'
import { Card } from './CardComponent'
import { EnergyConsumptionReport } from '../../../api/types'

type Props = {
  locations: EnergyConsumptionReport
  startDate: any
  endDate: any
}

function getLocationsTotals(locations: EnergyConsumptionReport) {
  const locationsTotalConsumption = locations?.reduce((sum, location) => sum + location.location_total_consumption, 0);
  const locationsTotalGeneratedPower = locations?.reduce((sum, location) => sum + location.location_total_generated_power, 0);
  const locationTotalCarbonFootprint = locations?.reduce((sum, location) => sum + location.location_total_carbon_footprint, 0);

  return [
    addCommasToNumber(locationsTotalConsumption),
    addCommasToNumber(locationsTotalGeneratedPower),
    addCommasToNumber(locationTotalCarbonFootprint)
  ]
}

function getLocationsPercentages(locations: EnergyConsumptionReport, current: string, previous: string) {
  const currentValue = locations?.reduce((sum, location) => sum + location[current], 0)
  const previousValue = locations?.reduce((sum, location) => sum + location[previous], 0);

  if (currentValue > 0 && previousValue > 0) {
    const percentageChange = ((previousValue - currentValue) / previousValue) * 100
    return Number(percentageChange.toFixed(2));
  }
  return 0;
}

export const EnergyReportTitlePage = ({ locations, startDate, endDate }: Props) => {
  const [locationsTotalConsumption, locationsTotalGeneratedPower, locationTotalCarbonFootprint] = getLocationsTotals(locations)

  return (
    <Box
      border={'solid: 2px'}
      gap="120px"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      textAlign="center"
      pt={20}
    >
      <Flex direction="column" alignItems="center" gap={58}>
        <Box>
          <img width={86} height={86} src={fileIcon} alt="icon" />
        </Box>
        <Flex direction="column" alignItems="center" gap={2}>
          <Stack height="fit-content">
            <Text
              fontSize="40px"
              fontWeight="700"
              fontFamily={'Poppins'}
              color="#0A3054"
              align="center"
              m={0}
              p={0}
            >
              Energy Consumption Report
            </Text>
          </Stack>
          <Text fontSize="16px" fontWeight={400} color="#484848" fontFamily="Inter">
            {`${formatDate(startDate)} - ${formatDate(endDate)}`}
          </Text>
        </Flex>
        <Flex>
          {(() => {
            const message = locations?.find((x) => x.message.length > 0)?.message
            return message ? (
              <Text fontSize="16px" fontWeight={400} color="#3893ed" fontFamily="Inter">
                {message}
              </Text>
            ) : null
          })()}
        </Flex>
        <Flex gap={3} wrap="wrap" width="90%" justifyContent="center">
          {locations?.map((location) => (
            <Flex
              key={location.location_id}
              border="1px solid #157FEE"
              borderRadius="full"
              p="3px 20px"
              sx={{ bgcolor: 'red' }}
              bg="#E8EEFD"
              alignItems="center"
              justifyContent="center"
              m="0px"
            >
              <img
                src={checkIcon}
                alt="icon"
                style={{ width: '20px', height: '20px', alignSelf: 'center' }}
              />
              <Text
                className={'locationName'}
                fontWeight="700"
                fontSize="20px"
                fontFamily="Inter"
                color="#157FEE"
              >
                {location.location_name}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Flex direction="column" fontSize={20} fontWeight={700} gap={5}>
        <Text color="#157FEE" fontFamily="Inter">
          Total Consumption
        </Text>
        <Flex gap={8}>
          <Card
            title="Total consumed energy"
            measurementType={KILOWATT_HOUR}
            value={locationsTotalConsumption}
            percentageChange={getLocationsPercentages(
              locations,
              'location_total_consumption',
              'previous_location_total_consumption',
            )}
          >
            <ConsumedEnergyIcon boxSize="18px" color="text.secondary" />
          </Card>

          <Card
            title="Produced energy"
            measurementType={KILOWATT_HOUR}
            value={locationsTotalGeneratedPower}
            percentageChange={getLocationsPercentages(
              locations,
              'location_total_generated_power',
              'previous_location_total_generated_power',
            )}
          >
            <ProducedEnergyIcon boxSize="18px" color="text.secondary" />
          </Card>

          <Card
            title="Carbon footprint"
            measurementType={CARBON_FOOTPRINT_KG}
            value={locationTotalCarbonFootprint}
            percentageChange={getLocationsPercentages(
              locations,
              'location_total_carbon_footprint',
              'previous_location_total_carbon_footprint',
            )}
          >
            <CloudIcon boxSize="18px" color="text.secondary" />
          </Card>
        </Flex>
      </Flex>
      <Stack mb="100px">
        <img src={logoSvg} alt="logo" />
      </Stack>
    </Box>
  )
}
