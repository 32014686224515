import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Container,
  Grid,
  FormControl,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import { OptionBase } from 'chakra-react-select'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useGetLocationsInfoQuery } from '../../locations/api/locationsApi'
import { useCreateDeviceGroupMutation } from '../api/deviceGroupConfigurationApi'
import { DeviceResult, SelectOption } from '../api/types'
import { generateSelectOptions } from '../utils'
import { ControlledSelect } from './ControlledSelect'

interface DevicesType extends OptionBase {
  label: string
  value: number
}

interface FormValue {
  devices: DevicesType[]
  deviceGroupName: string
  location: SelectOption
}

const defaultValues: FormValue = { devices: [], deviceGroupName: '', location: null}

type Props = {
  ungroupedDevices: DeviceResult[]
}
export const CreateDeviceGroupModal = ({ ungroupedDevices }: Props) => {
  const {
    reset,
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<FormValue>({ defaultValues })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [createDeviceGroup, {isLoading}] = useCreateDeviceGroupMutation()
  const { data: locationsData } = useGetLocationsInfoQuery()

  const submit: SubmitHandler<FormValue> = async (data) => {
    const body = { name: data.deviceGroupName, device_ids: data.devices.map((x) => x.value), location: Number(data.location.value)}
    try {
      await createDeviceGroup(body).unwrap()
      toast.success(`Group created successfully`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }catch (error) {
      toast.error(`${error.data.message ? error.data.message : "Something went wrong!"}`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }

    reset()
    onClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      <Button
        mt={8}
        alignSelf="flex-start"
        colorScheme="button.primary"
        onClick={onOpen}
        // disabled={!locationId}
        w={240}
        h={12}
      >
        Create Device Group
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <Container as="form" mb={12} onSubmit={handleSubmit(submit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Create Device Group</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid templateColumns="repeat(1, 1fr)" gap={5}>
                <FormControl isInvalid={!!errors.deviceGroupName}>
                  <Input
                    name="deviceGroupName"
                    variant="outline"
                    placeholder="Device group name"
                    {...register('deviceGroupName', {
                      required: 'Device group name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.deviceGroupName && errors.deviceGroupName.message}
                  </FormErrorMessage>
                </FormControl>
                <ControlledSelect
                  control={control}
                  isMulti
                  name="devices"
                  id="devices"
                  options={
                    ungroupedDevices?.map((x) => {
                      return { label: x.title, value: x.id }
                    }) || []
                  }
                  placeholder="Devices"
                  label="Devices"
                  rules={{ required: 'Please enter at least one device.' }}
                />
                 <ControlledSelect
                  control={control}
                  name="location"
                  id="location"
                  options={generateSelectOptions(locationsData?.results)}
                  placeholder="Location"
                  label="Location"
                  rules={{ required: 'Please select location' }}
                />
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={handleClose} isLoading={isLoading}>Cancel</Button>
              <Button type="submit" colorScheme="blue" isLoading={isLoading} >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Container>
      </Modal>
    </>
  )
}
