import React from "react"
import SingleSelect from "../../../reports/components/SingleSelect"
import { HStack, Input, InputLeftElement, InputGroup, Button, Box, Text, Select } from "@chakra-ui/react"
import { AddIcon, MinusIcon } from "@chakra-ui/icons"
import { STATES } from "../../../../utils/consts"
import { v4 as uuidv4 } from "uuid"

const SEVERITY_OPTIONS = [
    { label: "Critical", value: "Critical" },
    { label: "Major", value: "Major" },
    { label: "Minor", value: "Minor" },
    { label: "Warning", value: "Warning" },
    { label: "Info", value: "Info" },
]

export default function MultistateAlarm({ watch, setValue, register }) {
    const selectedState = watch("selectedState") || null
    const selectedStateFields = watch("selectedStateFields") || [{ id: uuidv4(), value: null, description: "", severity: "Critical" }]

    const setSelectedState = (selectedState) => {
        setValue("selectedState", selectedState)
        setValue("selectedStateFields", [{ id: uuidv4(), value: null, description: "", severity: "Critical" }])
    }

    const handleAddField = () => {
        if (selectedState === 2 && selectedStateFields.length >= 16) return
        setValue(
            "selectedStateFields",
            [
                ...selectedStateFields,
                { id: uuidv4(), value: null, description: "", severity: "Critical" },
            ])
    }

    const handleRemoveField = (id) => {
        const updatedFields = selectedStateFields.filter(field => field.id !== id)
        setValue("selectedStateFields", updatedFields)
    }

    const handleFieldChange = (index, field, value) => {
        const updatedFields = [...selectedStateFields]

        if (field === "value" && value !== "") {
            let newValue = Number(value)
            let maxLimit = selectedState === 1 ? 65535 : 15

            if (newValue > maxLimit) {
                newValue = maxLimit
            }

            updatedFields[index][field] = newValue
        } else {
            updatedFields[index][field] = value
        }

        setValue("selectedStateFields", updatedFields)
    }

    return (
        <>
            <SingleSelect
                key="states"
                items={STATES}
                isDisabled={false}
                {...register("selectedState")}
                setSelectedOption={setSelectedState}
                selectedOption={selectedState}
                required={selectedState === null}
                header="Select state"
            />
            {selectedState && (
                <Box>
                    {selectedStateFields.map((field, index) => (
                        <HStack key={field.id} spacing={4} align="center" mb={2}>
                            <InputGroup>
                                <InputLeftElement zIndex="0">
                                    <Text>{selectedState === 1 ? "int" : "bit"}</Text>
                                </InputLeftElement>
                                <Input
                                    type="number"
                                    name={`value-${index}`}
                                    value={field.value}
                                    onChange={(e) => {
                                        const parseValue = e.target.value === "" ? "" : Number(e.target.value)
                                        handleFieldChange(index, "value", parseValue)
                                    }}
                                    max={selectedState === 1 ? 65535 : 15}
                                    min={0}
                                    placeholder={selectedState === 1 ? "0-65535" : "0-15"}
                                    borderColor={typeof field.value === 'number' ? 'border.strong' : 'red.500'}
                                />
                            </InputGroup>
                            <Input
                                name={`description-${index}`}
                                value={field.description}
                                onChange={(e) => handleFieldChange(index, "description", e.target.value)}
                                placeholder="Description"
                                borderColor={field.description ? 'border.strong' : 'red.500'}
                            />
                            <Select
                                name={`severity-${index}`}
                                value={field.severity}
                                onChange={(e) => handleFieldChange(index, "severity", e.target.value)}
                                _hover={{
                                    cursor: "pointer",
                                }}
                            >
                                {SEVERITY_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </Select>
                            <Button disabled={selectedState === 2 && selectedStateFields?.length >= 16} onClick={handleAddField} colorScheme="teal" size="sm">
                                <AddIcon />
                            </Button>
                            <Button disabled={selectedStateFields?.length === 1} onClick={() => handleRemoveField(field.id)} colorScheme="red" size="sm">
                                <MinusIcon />
                            </Button>
                        </HStack>
                    ))}
                </Box>
            )}
        </>
    )
}
