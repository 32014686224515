import React, { useCallback, useRef } from 'react'
import { CloseIcon } from '@chakra-ui/icons'
import { ToastContainer, toast } from 'react-toastify'
import useAlertsSocket from '../hooks/useAlertsSocket'
import AlarmToast from './AlarmToast'
import FooterAlarmToast from './FooterAlarmToast'
import { useLocation } from 'react-router-dom'
import { AlarmData } from '../../alarms/api/types'

const AlarmToastManager = () => {
  const toastIds = useRef<number[]>([])
  const footerToastId = useRef<React.ReactText | null>(null)
  const location = useLocation()

  const filterToastIds = useCallback(
    (toastId) => toastIds.current.filter((id) => id !== toastId),
    [],
  )

  const createOrUpdateToastFooter = useCallback((remainingAlarms: number) => {
    if (footerToastId.current) {
      toast.update(footerToastId.current, {
        render: ({ closeToast }) => (
          <FooterAlarmToast
            remainingAlarms={remainingAlarms}
            closeToast={() => {
              closeToast()
              toastIds.current.forEach((id) => toast.dismiss(id))
              footerToastId.current = null
              toastIds.current = []
            }}
          />
        ),
      })
    } else {
      const toastId = toast(
        ({ closeToast }) => (
          <FooterAlarmToast
            remainingAlarms={remainingAlarms}
            closeToast={() => {
              closeToast()
              toastIds.current.forEach((id) => toast.dismiss(id))
              footerToastId.current = null
              toastIds.current = []
            }}
          />
        ),
        {
          position: 'bottom-right',
          autoClose: false,
          closeButton: false,
        }
      )
  
      footerToastId.current = toastId
    }
    
    // Always make sure the footer appears last by re-adding it at the end.
    setTimeout(() => {
      if (footerToastId.current) {
        toast.dismiss(footerToastId.current)
        footerToastId.current = toast(
          ({ closeToast }) => (
            <FooterAlarmToast
              remainingAlarms={remainingAlarms}
              closeToast={() => {
                closeToast()
                toastIds.current.forEach((id) => toast.dismiss(id))
                footerToastId.current = null
                toastIds.current = []
              }}
            />
          ),
          {
            position: 'bottom-right',
            autoClose: false,
            closeButton: false,
          }
        )
      }
    }, 50)
  }, [])

  const createOrUpdateAlarmToast = useCallback(
    (alarm) => {
      if (toastIds.current?.length >= 2) {
        if (toastIds.current.includes(alarm.id)) {
          const oldestToastId = toastIds.current[0]
          toast.dismiss(oldestToastId)
          toastIds.current = filterToastIds(oldestToastId)
          return
        }
      }

      toast(
        ({ closeToast }) => (
          <AlarmToast
            alarm={alarm}
            closeToast={() => {
              closeToast()
              toastIds.current = filterToastIds(alarm.id)
            }}
          />
        ),
      {
        position: 'bottom-right',
        autoClose: false,
        toastId: alarm.id,
        closeButton: <CloseIcon cursor="pointer" />,
      },
    )
    toastIds.current.push(alarm.id)
  }, [filterToastIds])

  const handleAlarms = useCallback(
    (data: AlarmData) => {
      if (location.pathname.includes('alarms')) {
        return
      }

      if (data.active_alarms >= 2) {
        if (data.last_alarms.length > 0) {
          const lastAlarms = data.last_alarms.map((a) => a.id)
          const currentAlarms = toastIds.current
          const areEqual = lastAlarms.every(
            (alarm, index) => alarm === currentAlarms[index],
          )

          if (areEqual) return
          data.last_alarms.forEach((alarm) => createOrUpdateAlarmToast(alarm))
        }

        const remainingAlarms = data.active_alarms - data.last_alarms.length
        createOrUpdateToastFooter(remainingAlarms)
      }
    },
    [createOrUpdateToastFooter, createOrUpdateAlarmToast, location.pathname],
  )

  useAlertsSocket(handleAlarms)

  return <ToastContainer newestOnTop={false} />
}

export default AlarmToastManager
