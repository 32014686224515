import React, { useEffect } from 'react'
import { Flex, Grid, Text, Spinner, Badge } from '@chakra-ui/react'
import Card from './Card'
import NotFound from './NotFound'
import { LayerGroupIcon } from '../../../assets/icons/Groups'
import { addCommasToNumber } from '../../../utils/helpers'
import { DashboardOverviewLocation } from '../../dashboard/types'
import { useGetGroupsDataQuery } from '../api/automationsApi'
import { CURRENT_USAGE_POLLING_INTERVAL } from '../../../utils/consts'

interface GroupsProps {
  filteredLocations: DashboardOverviewLocation[]
  setIndex: React.Dispatch<React.SetStateAction<number>>
  setFilteredGroups: React.Dispatch<any>
  querySearchParams: any
}

export default function Groups({ filteredLocations, setIndex, setFilteredGroups, querySearchParams }: GroupsProps) {
  const { data, isFetching, isLoading, refetch } = useGetGroupsDataQuery({
    from_date: querySearchParams.from,
    to_date: querySearchParams.to,
    location: filteredLocations?.map((location) => location.id.toString()),
  })

  const { currentData, isFetching: isCurrentDataFetching, isLoading: isCurrentDataLoading, refetch: refetchCurrentData } = useGetGroupsDataQuery(
    {
      location: filteredLocations?.map((location) => location.id.toString()),
      latest: true,
    },
    {
      pollingInterval: CURRENT_USAGE_POLLING_INTERVAL,
    },
  )

  const mergedGroups = (currentData || []).map((currentGroup) => {
    const historicalGroup = (data || []).find((group) => group.id === currentGroup.id);

    return {
      ...currentGroup,
      period_consumption: historicalGroup?.period_consumption || null,
    }
  })

  useEffect(() => {
    refetch()
    refetchCurrentData()
  }, [filteredLocations, querySearchParams, refetch, refetchCurrentData])

  const groups = mergedGroups || []

  if (isFetching || isLoading || isCurrentDataFetching || isCurrentDataLoading) {
    return <Flex justifyContent="center" alignItems="center" height="200px"><Spinner size="xl" /></Flex>
  }

  if (groups.length === 0) {
    return <NotFound title="No smart groups" description="Currently there are no groups that support automation and smart control" header={false} />
  }

  const handleCardClick = (e: React.MouseEvent, id: number) => {
    e.preventDefault()
    const selectedGroup = groups.filter((group) => group.id === id)
    setFilteredGroups(selectedGroup)
    setIndex(2)
  }

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {groups.map((group) => (
        <Card
          title={
            <Flex align="center" justify="space-between" gap={1}>
              {group.name}
              {group.current_values?.device_count > 0 && (
                <Badge colorScheme="blue" color="white" fontSize="0.8em" borderRadius="full">
                  {group.current_values.device_count}
                </Badge>
              )}
            </Flex>
          }
          id={group.id}
          key={group.id}
          onClick={(e) => handleCardClick(e, group.id)}
          cardIcon={<LayerGroupIcon boxSize="24px" />}
        >
          <Flex direction="column">
            {group?.period_consumption?.total_consumption > 0 && <Text>Consumption: {addCommasToNumber(Math.round(group.period_consumption?.total_consumption || 0))} kWh</Text>}
            {group?.current_values?.total_consumption > 0 && <Text>Current usage: {addCommasToNumber(Math.round(group.current_values?.total_consumption || 0))} kW</Text>}
            {group?.period_consumption?.total_generated_power > 0 && <Text>Generated power: {addCommasToNumber(Math.round(group.period_consumption?.total_generated_power || 0))} kWh</Text>}
            {group?.current_values?.total_generated_power > 0 && <Text>Current generated power: {addCommasToNumber(Math.round(group.current_values?.total_generated_power || 0))} kW</Text>}
          </Flex>
        </Card>
      ))}
    </Grid>
  )
}
