import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { Toggle } from '../../../app/components/Button/Toggle'
import { useToggleDeviceByIdMutation } from '../api/automationsApi'
import SocketIcon from '../../../assets/icons/Socket'

interface CardProps {
    title: any
    id: number
    toggle?: boolean
    children?: React.ReactNode
    cardIcon?: React.ReactNode
    onClick?: (e: React.MouseEvent, id: number) => void
}

export default function Card(props: CardProps) {
    const { title, id, toggle, children, cardIcon, onClick } = props
    const [toggleDeviceById, { isLoading: isToggleDeviceLoading, data }] =
        useToggleDeviceByIdMutation()

    const isEnabled = toggle || data?.enabled

    const handleToggle = () => {
        toggleDeviceById({ id, action: isEnabled ? 'off' : 'on' })
    }

    const handleClick = (e: React.MouseEvent) => {
        if (onClick) {
            onClick(e, id)
        }
    }

    return (
        <Box
            maxW="376px"
            minW="184px"
            bg="white"
            rounded="xl"
            onClick={onClick ? handleClick : undefined}
            _hover={onClick ? { cursor: 'pointer', background: '#F0F0F0' } : undefined}
        >
            <Flex
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                h="184px"
                p={4}
            >
                <Flex direction="column" gap="1" alignItems="center" textAlign="center">
                    <Flex
                        bg="#F0F0F0"
                        rounded="xl"
                        boxSize="40px"
                        alignItems="center"
                        justifyContent="center"
                    >
                       {cardIcon ?? <SocketIcon boxSize="24px" />}
                    </Flex>
                    <Text mt={3} fontWeight="bold">{title}</Text>
                    {children}
                </Flex>

                {toggle !== undefined && (
                    <Toggle
                        handleClick={handleToggle}
                        isActive={isEnabled}
                        isLoading={isToggleDeviceLoading}
                    />
                )}
            </Flex>
        </Box>
    )
}