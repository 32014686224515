import { CloseIcon } from '@chakra-ui/icons'
import { Box, Flex, Text, Link, Button } from '@chakra-ui/react'
import React from 'react'

interface Props {
  closeToast: () => void
  remainingAlarms: number
}

const FooterAlarmToast: React.FC<Props> = ({ closeToast, remainingAlarms }) => (
  <Box
    p="12px"
    bg="white"
    rounded="md"
    boxShadow="4px 14px 38px 0px rgba(46, 46, 46, 0.12)"
  >
    <Flex gap="4">
      <Text fontSize="12px" fontWeight="700" color="black" maxW="245px">
        There are {remainingAlarms} more active alarms . Open the{' '}
        <Link href="/alarms" color="icon.strong">
          alarm page
        </Link>{' '}
        to see them.
      </Text>
      <Button
        variant="ghost"
        p="12px"
        onClick={closeToast}
        sx={{
          _hover: {
            background: 'none',
            boxShadow: 'none',
          },
        }}
      >
        <Text color="text.dark" fontSize="14px" fontWeight="700">
          Dismiss all
        </Text>
        <CloseIcon ml="2" />
      </Button>
    </Flex>
  </Box>
)

export default FooterAlarmToast
