import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Container,
  Grid,
  FormControl,
  Input,
  FormErrorMessage,
  IconButton,
} from '@chakra-ui/react'
import { OptionBase } from 'chakra-react-select'
import { toast } from 'react-toastify'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { EditTwoIcon } from '../../../assets/icons/EditTwoIcon'
import { useGetLocationsInfoQuery } from '../../locations/api/locationsApi'
import { useUpdateDeviceGroupMutation } from '../api/deviceGroupConfigurationApi'
import { DeviceGroupListType, DeviceResult, SelectOption } from '../api/types'
import { generateSelectOptions } from '../utils'
import { ControlledSelect } from './ControlledSelect'

interface DevicesType extends OptionBase {
  label: string
  value: number
}

interface FormValue {
  devices: DevicesType[]
  deviceGroupName: string
  location: SelectOption
}
type Props = {
  deviceGroup: DeviceGroupListType,
  devicesWhitOutGroup: DeviceResult[]
}

export const EditDeviceGroupModal = ({ deviceGroup, devicesWhitOutGroup }: Props) => {
  const {
    reset,
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValue>()

  const [updateDeviceGroup, {isLoading: isUpdating}] = useUpdateDeviceGroupMutation()
  const { data: locationsData, isLoading } = useGetLocationsInfoQuery()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (isOpen) {
      setValue(
        'devices',
        deviceGroup.devices.map((x) => ({ value: x.id, label: x.title }))
      );
      setValue('deviceGroupName', deviceGroup.name);
      setValue('location', generateSelectOptions(locationsData?.results)?.find(x => Number(x.value) === Number(deviceGroup.location)) )
    }
  }, [deviceGroup, isOpen, setValue, isLoading]);

  const submit: SubmitHandler<FormValue> = async (data) => {
    const body = {
      name: data.deviceGroupName,
      device_ids: data.devices.map((x) => x.value),
      location: Number(data.location.value),
      id: deviceGroup.id,
    }
    try {
      await updateDeviceGroup(body).unwrap()
      toast.success(`Group updated successfully`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    } catch (error) {
      toast.error(`${error.data.message ? error.data.message : "Something went wrong!"}`, {
        style: { padding: '10px 20px' },
        isLoading: false,
        autoClose: 3000,
      })
    }
  

    onClose()
  }

  const handleClose = () => {
    reset()
    onClose()
  }

  return (
    <>
      <IconButton
        background="none"
        aria-label="edit device group"
        size="md"
        _focus={{ boxShadow: 'none' }}
        icon={<EditTwoIcon />}
        onClick={onOpen}
      />

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <Container as="form" mb={12} onSubmit={handleSubmit(submit)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Device Group</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <Grid templateColumns="repeat(1, 1fr)" gap={5}>
                <FormControl isInvalid={!!errors.deviceGroupName}>
                  <Input
                    name="deviceGroupName"
                    variant="outline"
                    placeholder="Device group name"
                    {...register('deviceGroupName', {
                      required: 'Device group name is required',
                    })}
                  />
                  <FormErrorMessage>
                    {errors.deviceGroupName && errors.deviceGroupName.message}
                  </FormErrorMessage>
                </FormControl>
                <ControlledSelect
                  control={control}
                  isMulti
                  name="devices"
                  id="devices"
                  options={
                    [...devicesWhitOutGroup, ...deviceGroup.devices]?.map((x) => {
                      return { label: x.title, value: x.id }
                    }) || []
                  }
                  placeholder="Devices"
                  label="Devices"
                  rules={{ required: 'Please enter at least one device.' }}
                />
                <ControlledSelect
                  control={control}
                  name="location"
                  id="location"
                  options={generateSelectOptions(locationsData?.results)}
                  placeholder="Location"
                  label="Location"
                  rules={{ required: 'Please select location' }}
                />
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={handleClose} isLoading={isUpdating}>Cancel</Button>
              <Button type="submit" colorScheme="blue"  isLoading={isUpdating}>
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Container>
      </Modal>
    </>
  )
}
