import { createIcon } from '@chakra-ui/icon'

export const ControlIcon = createIcon({
    displayName: 'SwitchIcon',
    viewBox: '0 0 16 16',
    path: (
      <path
        fill="currentColor"
        d="M10 2.29v2.124c.566.247 1.086.6 1.536 1.05C12.48 6.408 13 7.664 13 9s-.52 2.591-1.464 3.536S9.336 14 8 14s-2.591-.52-3.536-1.464S3 10.336 3 9s.52-2.591 1.464-3.536c.45-.45.97-.803 1.536-1.05V2.29a7 7 0 1 0 4 0M7 0h2v8H7z"
      />
    ),
})
