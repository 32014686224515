import { createContext, PropsWithChildren, useContext, useState } from 'react'

const AlarmsCountContext = createContext<{
  alarmsCount: number
  autoResolvedAlarmCount: number
  setAlarmsCount: React.Dispatch<React.SetStateAction<number>>
  setAutoResolvedAlarmCount: React.Dispatch<React.SetStateAction<number>>
}>({
  alarmsCount: 0,
  autoResolvedAlarmCount: 0,
  setAlarmsCount: () => null,
  setAutoResolvedAlarmCount: () => null
})


export const useAlarmsCountContext = () => {
  return useContext(AlarmsCountContext)
}

export const AlarmsCountContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [alarmsCount, setAlarmsCount] = useState<number>(0)
  const [autoResolvedAlarmCount, setAutoResolvedAlarmCount] = useState<number>(0)
  return (
    <AlarmsCountContext.Provider value={{ alarmsCount, autoResolvedAlarmCount, setAutoResolvedAlarmCount, setAlarmsCount }}>
      {children}
    </AlarmsCountContext.Provider>
  )
}

export default AlarmsCountContext
