import {
  Analysis,
  Compare,
  Device,
  Location,
  ConsumptionBreakDownRequest,
  AnalysisDevice,
} from './types'

import { pythonApi } from '../../../app/services/api/api'
import {
  compareDevicesMockData,
  getDevicesMockData,
  getLocationsMock,
} from '../../../mockData/analysis'
import { parseISO, addHours } from 'date-fns'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalysisConsumptionBreakdown: builder.query<
      AnalysisDevice[],
      ConsumptionBreakDownRequest
    >({
      query: (body) => {
        return {
          url: 'analysis/devices-period-consumption/',
          method: 'POST',
          body,
        }
      },
    }),
    getAnalysisLocations: builder.query<Location.Response, void>({
      // query: () => "locations",
      query: () => 'locations/?&paging=false&short=false',
      transformResponse: (response: any, _meta, arg) => {
        const mockResponse = getLocationsMock()
        return mockResponse ? mockResponse : response
      },
    }),
    getDeviceHeatMap: builder.query<any, Analysis.Request>({
      query: (args) => {
        const filteredArgs = Object.fromEntries(
          Object.entries(args).filter(([_, value]) => value !== null),
        )
        return {
          url: 'analysis/',
          params: filteredArgs,
        }
      },
      transformResponse: (response: any, _meta, arg) => {
        if (!response.heat_map) return response

        const heatMap = response.heat_map

        // Parse existing timestamps
        const timestamps = heatMap.map((entry: any) => parseISO(entry.time))

        // Determine the range of time
        const startTime = timestamps[0]
        startTime.setHours(0, 0, 0, 0)

        const endTime = timestamps[timestamps.length - 1]
        endTime.setHours(23, 0, 0, 0)

        // Generate a timeline from start to end by 1 hour intervals
        const completeTimeline = []
        let currentTime = startTime
        while (currentTime <= endTime) {
          completeTimeline.push(currentTime)
          currentTime = addHours(currentTime, 1)
        }

        const normalizeTime = (time: string) => time.split('.')[0] + 'Z' // Normalize by removing milliseconds

        // Create a map for the existing heat_map data
        const parseHeatMap = new Map(heatMap.map((entry: any) => [entry.time, entry]))

        // Fill missing times with null values
        const filledHeatMap = completeTimeline.map((time) => {
          const formattedTime = normalizeTime(time.toISOString()) // Normalize to match keys in parseHeatMap

          return parseHeatMap.has(formattedTime)
            ? parseHeatMap.get(formattedTime)
            : {
                time: formattedTime,
                avg_consumption: null,
                avg_generated_power: null,
              }
        })

        // Return the modified response
        return {
          ...response,
          heat_map: filledHeatMap,
        }
      },
    }),
    getAnalysisDevices: builder.query<Device.Data[], { locationId: string | number }>({
      query: ({ locationId }) => ({
        url: `/analysis/location-devices/?location_id=${locationId}`,
        method: 'GET',
      }),
    }),
    getCompareDevices: builder.query<Compare.Response, Compare.Request>({
      query: (args) => {
        return {
          url: '/data/compare_devices/',
          method: 'POST',
          body: args,
        }
      },
      transformResponse: (response: Compare.Response, _meta, arg) => {
        // MOCK DATA START

        const mockResponse = compareDevicesMockData(arg.date_ranges, arg.devices)

        const result = mockResponse
          ? {
              ...response,
              ...mockResponse,
            }
          : response

        // MOCK DATA END

        // @ts-ignore
        result.results.sort((a, b) => Number(b.is_main) - Number(a.is_main))
        return result
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAnalysisConsumptionBreakdownQuery,
  useGetDeviceHeatMapQuery,
  useGetAnalysisLocationsQuery,
  useGetAnalysisDevicesQuery,
  useGetCompareDevicesQuery,
} = extendedApi
