import { Flex, Input, Text, InputGroup, InputRightElement } from "@chakra-ui/react"
import React from "react"
import SingleSelect from "../../../reports/components/SingleSelect"
import { comparisonsArr } from "../../../analysis/utils"

export default function LevelAlarm({ watch, setValue, register }) {
    const value_comparison = watch('value_comparison') || null
    const value = watch('value') || null

    return (
        <Flex direction="column" gap="2">
            <Flex justify="space-between" gap="2" align="center">
                <SingleSelect
                    header="Select a comparison"
                    items={comparisonsArr}
                    required={value_comparison === null}
                    setSelectedOption={(selectedOptionId: string) =>
                        setValue('value_comparison', selectedOptionId)
                    }
                    selectedOption={value_comparison}
                />
                <Text>-</Text>
                <Input
                    width="20%"
                    {...register('value')}
                    type="number"
                    placeholder="Value"
                    borderColor={value !== null ? 'border.strong' : 'red.500'}
                />
            </Flex>

            <Text fontSize="xs" color="gray">
                * Optional fields (Hysteresis values must be greater than 0 to avoid unexpected behavior)
            </Text>
            <Flex justify="space-between" gap="2" align="center">
                <InputGroup width="50%">
                    <Input
                        {...register('hysteresis_upper')}
                        type="number"
                        min={1}
                        onChange={(e) => {
                            let parseValue = e.target.value === "" ? "" : Number(e.target.value)
                            if (parseValue === 0) {
                                e.target.value = "1" // Ensures 0 become 1
                            } else {
                                e.target.value = parseValue.toString()
                            }
                        }}
                        placeholder="Hysteresis Upper Limit"
                    />
                    <InputRightElement zIndex="0">
                        <Text>%</Text>
                    </InputRightElement>
                </InputGroup>

                <Text>-</Text>

                <InputGroup width="50%">
                    <Input
                        {...register('hysteresis_lower')}
                        type="number"
                        min={1}
                        onChange={(e) => {
                            let parseValue = e.target.value === "" ? "" : Number(e.target.value)
                            if (parseValue === 0) {
                                e.target.value = "1" // Ensures 0 become 1
                            } else {
                                e.target.value = parseValue.toString()
                            }
                        }}
                        placeholder="Hysteresis Lower Limit"
                    />
                    <InputRightElement zIndex="0">
                        <Text>%</Text>
                    </InputRightElement>
                </InputGroup>
            </Flex>
        </Flex>
    )
}
