import { CloseIcon } from '@chakra-ui/icons'
import { Flex, Text, Box } from '@chakra-ui/react'
import { format } from 'date-fns'
import React from 'react'
import { Label } from '../../../app/components/Label/Label'
import { Alarm } from '../../alarms/api/types'
import { getDevices } from '../../../utils/helpers'

interface Props {
  alarm: Alarm
  closeToast: () => void
}

const AlarmToast: React.FC<Props> = ({ alarm, closeToast }) => {
  const device = getDevices(alarm)
  const uniqueLocations = Array.from(new Set(device.map((x) => x?.location.title)))

  return (
    <Box
      p="12px"
      bg="white"
      rounded="md"
      boxShadow="4px 14px 38px 0px rgba(46, 46, 46, 0.12)"
      minW="382px"
    >
      <Flex justifyContent="space-between" w="full">
        <Flex justifyContent="space-between" direction="column" gap="1">
          <Flex alignItems="center" gap={2}>
            <Text fontSize="14px" fontWeight="700" lineHeight="18px" color="text.dark">
              {alarm?.name}
            </Text>
            <Box>
              <Label type={alarm?.severity} />
            </Box>
          </Flex>
          <Flex gap={2} fontSize="12px" fontWeight="700" color="text.secondary">
            <Text>Device:</Text>
            <Text fontWeight="500">{device?.map((x) => x.title).join(", ")}</Text>
          </Flex>
          <Flex gap={2} fontSize="12px" fontWeight="700" color="text.secondary">
            <Text>Location:</Text>
            <Text fontWeight="500">{uniqueLocations?.join(", ")}</Text>
          </Flex>
          <Text fontSize="12px" fontWeight="400" color="text.secondary">
            {format(alarm.triggered_at, 'yyyy-MM-dd HH:mm:ss')}
          </Text>
        </Flex>
        <CloseIcon onClick={closeToast} cursor="pointer" />
      </Flex>
    </Box>
  )
}
export default AlarmToast
