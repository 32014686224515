import { pythonApi } from '../../../app/services/api/api'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    toggleDeviceById: builder.mutation<any, { id: number; action: string }>({
      query: ({ id, action }) => ({
        url: `v2/toggle-relay-device/${id}/`,
        method: 'POST',
        body: { action },
      }),
    }),
    getAllDevices: builder.query<any, void>({
      query: () => ({
        url: `/devices/`,
        method: 'GET',
      }),
    }),
    getGroupsData: builder.query<any, any>({
      query: (props) => {
        const searchParams = new URLSearchParams()
        if (props.location) {
          searchParams.set('location', props.location)
        }
        if (props.from_date) {
          searchParams.set('from_date', props.from_date)
        }
        if (props.to_date) {
          searchParams.set('to_date', props.to_date)
        }
        if (props.latest) {
          searchParams.set('latest', props.latest)
        }

        return {
          url: `/groups-automation/?${searchParams.toString()}`,
          method: 'GET',
        }
      },
      providesTags: ['ListGroups'],
    }),
    getDevicesData: builder.query<any, any>({
      query: (props) => {
        const searchParams = new URLSearchParams()
        if (props.group) {
          searchParams.set('group', props.group)
        }
        if (props.from_date) {
          searchParams.set('from_date', props.from_date)
        }
        if (props.to_date) {
          searchParams.set('to_date', props.to_date)
        }
        if (props.latest) {
          searchParams.set('latest', props.latest)
        }

        return {
          url: `/device-automation/?${searchParams.toString()}`,
          method: 'GET',
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllDevicesQuery,
  useToggleDeviceByIdMutation,
  useGetGroupsDataQuery,
  useGetDevicesDataQuery,
} = extendedApi
