import { Checkbox, Input, Flex, Text } from "@chakra-ui/react";
import React from "react";

export default function StatusAlarm({ notifyWhenActive, setNotifyWhenActive, delay, setDelay }) {
    return (
        <Flex direction="column" gap={2}>
            <Checkbox isChecked={notifyWhenActive} onChange={() => setNotifyWhenActive(!notifyWhenActive)}>
                Trigger alarm when device status is <strong>{notifyWhenActive ? "ON" : "OFF"}</strong>
            </Checkbox>
            <Flex align="center" gap={2}>
                <Text>Delay before triggering (seconds):</Text>
                <Input
                    type="number"
                    value={delay}
                    onChange={(e) => {
                        const value = e.target.value
                        setDelay(value === "" ? "" : Number(value))
                    }}
                    min={0}
                    width="80px"
                    borderColor={delay ? 'border.strong' : 'red.500'}
                />
            </Flex>
        </Flex>
    );
}