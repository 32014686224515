import { pythonApi } from '../../../app/services/api/api'
import {
  DeviceType,
  UserDevices,
  Location,
  MeasurementTypes,
  QueryParams,
  EnergyType,
  MeasurementTypesQueryParams,
} from '../../../app/services/api/types'
import { Device } from '../../analysis/api/types'

const extendedApi = pythonApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsInfo: builder.query<{ results: Location[] }, void>({
      query: () => ({
        url: `/locations/?paging=false&short=false`,
        method: 'GET',
      }),
      providesTags: ({ results }: any) => [
        'Locations',
        ...(results?.map(({ id }: Location) => ({ type: 'Locations', id })) || []),
      ],
    }),
    getDeviceTypes: builder.query<{ results: DeviceType[] }, { groupIds?: string[] }>({
      query: ({ groupIds }) => ({
        url: `/device-types/?groups=${groupIds}`,
        method: 'GET',
      }),
      providesTags: ['List'],
    }),
    getUserDevices: builder.query<UserDevices[], QueryParams>({
      query: ({
        locationIds,
        deviceTypeIds,
        deviceGroups,
        measurementTypeIds,
        energyType,
        deviceMeasurementType,
      }) => {
        const queryParams = new URLSearchParams()
        locationIds && queryParams.set('locations', locationIds.join(','))
        deviceTypeIds && queryParams.set('device_types', deviceTypeIds.join(','))
        energyType && queryParams.set('energy_type', energyType.toString())
        deviceGroups && queryParams.set('device_groups', deviceGroups.join(','))
        deviceMeasurementType && queryParams.set('device_measurement_type', deviceMeasurementType)
        measurementTypeIds &&
          queryParams.set('measurement_types', measurementTypeIds.join(','))

        return {
          url: `/user-devices/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
      providesTags: ['List'],
    }),
    getEnergyTypes: builder.query<{ results: EnergyType[] }, { deviceIds?: string[] }>({
      query: ({ deviceIds }) => ({
        url: `/device-classification/?devices=${deviceIds}`,
        method: 'GET',
      }),
    }),
    getMeasurementTypes: builder.query<{ results: MeasurementTypes[] },MeasurementTypesQueryParams>({
      query: ({ deviceTypeIds }) => {
        const queryParams = new URLSearchParams()
        deviceTypeIds && queryParams.set('device_types', deviceTypeIds.join(','))

        return {
          url: `/measurement-types/?${queryParams.toString()}`,
          method: 'GET',
        }
      },
    }),
    getLocationConsumptions: builder.query<any, any>({
      query: ({ locationId }) => {
        return {
          url: `/data/total/?from_date=2022-01-01&to_date=2023-12-01&location=${locationId}`,
          method: 'GET',
        }
      },
    }),
    getLocation: builder.query<Location, { id: number }>({
      query: ({ id }) => ({
        url: `/locations/${id}/`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'Locations', id: arg.id } as any],
    }),
    getDevice: builder.query<Device.Data, { id: number }>({
      query: ({ id }) => ({
        url: `/devices/${id}/`,
        method: 'GET',
      }),
      providesTags: (results: any) => [
        'Devices',
        ...(results?.map(({ id }: Device.Data) => ({ type: 'Devices', id })) || []),
      ],
    }),
    getLocationDevices: builder.query<Device.Data[], { id: number }>({
      query: ({ id }) => ({
        url: `/locations/${id}/devices/?paging=false&short=false`,
        method: 'GET',
      }),
      providesTags: (results: any) => [
        'Devices',
        ...(results?.map(({ id }: Device.Data) => ({ type: 'Devices', id })) || []),
      ],
    }),
    addLocation: builder.mutation<
      {
        title: string
        sublocations?: string
        price?: string
        description?: string
      },
      any
    >({
      query: (body) => ({
        url: '/locations/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Locations'] as any,
    }),
    addDevice: builder.mutation<
      { title: string; location_id: string; device_type_parameters: any },
      any
    >({
      query: (body) => ({
        url: '/devices/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Devices'] as any,
    }),
    editDevice: builder.mutation<
      { title: string; location_id: string; device_type_parameters: any },
      any
    >({
      query: ({ id, body }) => {
        return {
          url: `/devices/${id}/`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Devices'] as any,
    }),
    editLocation: builder.mutation<
      {
        title: string
        sublocations?: string
        price?: string
        description?: string
      },
      any
    >({
      query: ({ id, body }) => ({
        url: `/locations/${id}/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Locations'] as any,
    }),
    deleteLocation: builder.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `/locations/${id}/`,
        method: 'DELETE',
      }),
      // @ts-ignore
      invalidatesTags: ['Locations'] as any,
    }),
    deleteDevice: builder.query<any, { id: number }>({
      query: ({ id }) => ({
        url: `/devices/${id}/`,
        method: 'DELETE',
      }),
    }),
    getDevicesCsvReport: builder.mutation<any, any>({
      query: (body) => ({
        url: `/csv-data/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['List'],
    }),
    sendCsvReportViaEmail: builder.mutation<any, any>({
      query: (body) => ({
        url: `/send-csv-report/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['List'],
    }),
    sendPdfReportViaEmail: builder.mutation<any, any>({
      query: (body) => ({
        url: `/send-pdf-report/`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['List'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetLocationsInfoQuery,
  useGetLocationQuery,
  useGetLocationDevicesQuery,
  useGetLocationConsumptionsQuery,
  useGetDeviceTypesQuery,
  useGetUserDevicesQuery,
  useGetMeasurementTypesQuery,
  useGetEnergyTypesQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useEditDeviceMutation,
  useLazyDeleteLocationQuery,
  useLazyDeleteDeviceQuery,
  useAddDeviceMutation,
  useGetDeviceQuery,
  useGetDevicesCsvReportMutation,
  useSendCsvReportViaEmailMutation,
  useSendPdfReportViaEmailMutation,
} = extendedApi
