import { SVGProps } from 'react'
import { Badge, Box } from '@chakra-ui/react'

interface CommentIconProps extends SVGProps<SVGSVGElement> {
  count?: number
}

export function CommentIcon({ count = 0, ...props }: CommentIconProps) {
  return (
    <Box position="relative" display="inline-block">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="1em"
        height="1em"
        {...props}
      >
        <g fill="none" stroke="currentColor" strokeLinejoin="round">
          <path
            strokeLinecap="round"
            strokeWidth="2.5"
            d="M12 21a9 9 0 1 0-8-4.873L3 21l4.873-1c1.236.639 2.64 1 4.127 1"
          ></path>
          <path
            strokeWidth="3.75"
            d="M7.5 12h.01v.01H7.5zm4.5 0h.01v.01H12zm4.5 0h.01v.01h-.01z"
          ></path>
        </g>
      </svg>
      {count > 0 && (
        <Badge
          position="absolute"
          top="-17px"
          right="-17px"
          bg="blue.100"
          color="white"
          fontSize="12px"
          borderRadius="full"
          px="1.5"
        >
          {count}
        </Badge>
      )}
    </Box>
  )
}
