import { createIcon } from '@chakra-ui/icon'

export const LocationsIcon = createIcon({
  displayName: 'LocationsIcon',
  viewBox: '0 0 32 32',
  path: (
    <g
      id="Group_164"
      data-name="Group 164"
      transform="translate(2172.422 11499.743)"
      stroke="currentColor"
    >
      <path
        id="Path_1344"
        data-name="Path 1344"
        d="M3.7,4.623C9.562-1.241,21,.142,21,.142s1.452,11.444-4.413,17.309-10.727,3.665-13.652.756S-2.167,10.488,3.7,4.623Z"
        transform="translate(-2140.967 -11483.266) rotate(135)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g
        id="Ellipse_25"
        data-name="Ellipse 25"
        transform="translate(-2161 -11490)"
        fill="none"
        strokeWidth="2"
      >
        <circle cx="5" cy="5" r="5" stroke="none" />
        <circle cx="5" cy="5" r="4" fill="none" />
      </g>
    </g>
  ),
})
