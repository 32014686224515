import React, { useState } from "react";
import { Input } from "@chakra-ui/react";
import CustomModal from "../../reports/pages/components/CustomModal";

export default function AlarmCommentModal({ handleAction, isOpen, handleClose }) {
    const [comment, setComment] = useState("");

    const onResolve = () => {
        handleAction(comment)
        setComment("")
        handleClose()
    }

    return (
        <CustomModal
            title="Comment"
            tooltipLabel="Please provide a comment"
            handleSend={onResolve}
            isDisabled={!comment.trim()}
            isLoading={false}
            isOpen={isOpen}
            onClose={handleClose}
            buttonName="Comment"
        >
            <Input
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter comment"
                size="md"
            />
        </CustomModal>
    );
}
