import React from 'react'
import { Box, Flex, Spinner, Text, Grid } from '@chakra-ui/react'
import { Toggle } from '../../../app/components/Button/Toggle'
import { useToggleDeviceByIdMutation } from '../api/automationsApi'
import SocketIcon from '../../../assets/icons/Socket'

interface RelayProps {
  relays: any[]
  isLoading: boolean
}

interface CardProps {
  title: string
  id: number
  toggle: boolean
}

export const Card: React.FC<CardProps> = ({ title, id, toggle }) => {
  const [toggleDeviceById, { isLoading: isToggleDeviceLoading, data }] =
    useToggleDeviceByIdMutation()

  const isEnabled = toggle || data?.enabled

  const handleToggle = () => {
    toggleDeviceById({ id, action: isEnabled ? 'off' : 'on' })
  }
  return (
    <Box maxW="376px" minW="184px" bg="white" rounded="xl">
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        h="184px"
        p={4}
      >
        <Flex direction="column" gap="1" alignItems="center" textAlign="center">
          <Flex
            bg="#F0F0F0"
            rounded="xl"
            boxSize="40px"
            alignItems="center"
            justifyContent="center"
          >
            <SocketIcon boxSize="24px" />
          </Flex>
          <Text>{title}</Text>
        </Flex>

        <Toggle
          handleClick={handleToggle}
          isActive={isEnabled}
          isLoading={isToggleDeviceLoading}
        />
      </Flex>
    </Box>
  )
}

export const Relay: React.FC<RelayProps> = ({ relays, isLoading }) => {
  if (isLoading) {
    return <Spinner size="xl" />
  }

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={4}>
      {relays.map(({ title, id, enabled }) => (
        <Card title={title} id={id} toggle={enabled} />
      ))}
    </Grid>
  )
}