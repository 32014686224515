import { Flex, Text } from "@chakra-ui/react"
import Chip from "../../../app/components/Chip/Chip"
import Badge from '../../../app/components/Badge/Badge'

export default function Severity({ severity, setSelectedSeverity, selectType = 'multiple', isRequired = false }) {
    return (
        <Flex direction="column" gap="1">
            <Text fontSize="13px" color="text.dark">
                Severity
            </Text>
            <Flex justifyContent="space-between">
                <Chip
                    value="Critical"
                    name="Critical"
                    isRequired={isRequired}
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    selectType={selectType}
                    icons={{
                        selected: <Badge bg="red.500" boxSize="12px" />,
                        unselected: <Badge bg="red.500" boxSize="12px" />,
                    }}
                />
                <Chip
                    value="Major"
                    name="Major"
                    isRequired={isRequired}
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    selectType={selectType}
                    icons={{
                        selected: <Badge bg="red.300" boxSize="12px" />,
                        unselected: <Badge bg="red.300" boxSize="12px" />,
                    }}
                />
                <Chip
                    value="Minor"
                    name="Minor"
                    isRequired={isRequired}
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    selectType={selectType}
                    icons={{
                        selected: <Badge bg="orange.300" boxSize="12px" />,
                        unselected: <Badge bg="orange.300" boxSize="12px" />,
                    }}
                />
                <Chip
                    value="Warning"
                    name="Warning"
                    isRequired={isRequired}
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    selectType={selectType}
                    icons={{
                        selected: <Badge bg="yellow.400" boxSize="12px" />,
                        unselected: <Badge bg="yellow.400" boxSize="12px" />,
                    }}
                />
                <Chip
                    value="Info"
                    name="Info"
                    isRequired={isRequired}
                    selectedValues={severity}
                    setSelected={setSelectedSeverity}
                    selectType={selectType}
                    icons={{
                        selected: <Badge bg="icon.strong" boxSize="12px" />,
                        unselected: <Badge bg="icon.strong" boxSize="12px" />,
                    }}
                />
            </Flex>
        </Flex>
    )
}