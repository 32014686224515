import React, { useState } from 'react'
import { Tabs, TabList, Tab, Box } from '@chakra-ui/react'
import { CustomTabsProps } from './types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAlarmSeenMutation } from '../../../features/alarms/api/alarmsApi'
import { useAlarmsCountContext } from '../../../context/AlarmsCountContext'

const CustomTabs: React.FC<CustomTabsProps> = ({
  tabs,
  setActiveIndex,
  activeIndex,
  isIndicatorActive,
  isPastIndicatorActive,
  refetch,
  ...rest
}) => {
  const [internalIndex, setInternalIndex] = useState(0)
  const navigate = useNavigate()
  const location = useLocation()
  const [alarmSee] = useAlarmSeenMutation()
  const {setAutoResolvedAlarmCount} = useAlarmsCountContext()
  const isControlled = activeIndex !== undefined && setActiveIndex !== undefined
  const handleTabsChange = async (index: number) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('page', '1')
    navigate(`${location.pathname}?${searchParams.toString()}`)

    if(index === 1 && isPastIndicatorActive) {

      alarmSee({})
      .then(() => setAutoResolvedAlarmCount(0)) // Update count after API call
      .catch((error) => console.error("Error marking alarms as seen:", error))
    }
    if (isControlled) {
      setActiveIndex(index)
      return
    }

    setInternalIndex(index)
  }

  return (
    <Tabs
      variant="soft-rounded"
      w="fit-content"
      bg="white"
      rounded="3xl"
      colorScheme="gray"
      index={isControlled ? activeIndex : internalIndex}
      padding="4px"
      onChange={handleTabsChange}
      marginBottom="16px"
      {...rest}
    >
      <TabList padding="4px">
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            gap={2}
            alignItems="center"
            justifyContent="center"
            fontWeight="400"
            padding="0 20px"
            height="36px"
            _selected={{
              fontSize: '14px',
              fontWeight: '700',
              bg: '#F7F9FD',
              outline: 'none',
              shadow: 'none',
            }}
          >
            {tab.label}
            {((i === 0 && isIndicatorActive) || (i === 1 && isPastIndicatorActive)) && (
              <Box
                margin="0"
                width="8px"
                height="8px"
                bg="#157FEE"
                borderRadius="full"
              />
            )}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default CustomTabs
