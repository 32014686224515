import React from 'react'
import { useForm } from 'react-hook-form'
import { DateObject } from 'react-multi-date-picker'
import { Compare, Location } from '../api/types'
import { useGetAnalysisDevicesQuery, useGetAnalysisLocationsQuery } from '../api/analysisApi'
import DeviceDropDown from '../components/DeviceDropDown'
import RangeDatePicker from '../components/RangePicker'
import { createSearchParams, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { Button, Center, Checkbox, Divider, Flex, Select, Stack } from '@chakra-ui/react'

const getSearchParams = ({
  dateRange1,
  dateRange2,
  deviceId1,
  deviceId2,
  shouldCompareOneDevice,
}: any) => {
  const [startDate, endDate] = dateRange1.split('-')
  const date_ranges = [
    {
      from_date: dayjs(startDate).format('YYYY-MM-DD'),
      to_date: dayjs(endDate).format('YYYY-MM-DD'),
    },
  ]
  const devices = [deviceId1]
  if (shouldCompareOneDevice) {
    const [secondStartDate, secondEndDate] = dateRange2.split('-')
    date_ranges.push({
      from_date: dayjs(secondStartDate).format('YYYY-MM-DD'),
      to_date: dayjs(secondEndDate).format('YYYY-MM-DD'),
    })
  } else {
    devices.push(deviceId2)
  }

  return {
    date_ranges: date_ranges,
    devices: devices,
    shouldCompareOneDevice,
  }
}

const CompareForm: React.FC = () => {
  const { data: { results: locations = [] } = {} } = useGetAnalysisLocationsQuery()
  const { register, watch, handleSubmit, setValue } = useForm<Compare.FormFields>()
  const location1 = watch('locationId1')
  const location2 = watch('locationId2')

  const { data: primaryDevices } = useGetAnalysisDevicesQuery(
    {
      locationId: location1,
    },
    {
      skip: !location1,
    },
  )

  const { data: secondaryDevices } = useGetAnalysisDevicesQuery(
    {
      locationId: location2,
    },
    {
      skip: !location2,
    },
  )

  const navigate = useNavigate()
  const onSubmit = ({
    dateRange1,
    dateRange2,
    deviceId1,
    deviceId2,
    shouldCompareOneDevice,
  }: Compare.FormFields) => {
    const searchParams = getSearchParams({
      dateRange1,
      dateRange2,
      deviceId1,
      deviceId2,
      shouldCompareOneDevice,
    })

    navigate('/analysis/compare-analysis', { state: searchParams })
  }

  return (
    <Flex gap={6} direction="column" as="form" onSubmit={handleSubmit(onSubmit)}>
      <Checkbox {...register('shouldCompareOneDevice')}>Compare one device</Checkbox>
      <Stack spacing={6}>
        <Select size="lg" placeholder="Select location" {...register('locationId1')}>
          {locations.map(({ id, title }) => (
            <option value={id} key={title}>
              {title}
            </option>
          ))}
        </Select>
        {location1 && (
          <DeviceDropDown
            locationId={location1}
            {...register('deviceId1')}
            devices={primaryDevices}
          />
        )}
      </Stack>
      <RangeDatePicker
        {...register('dateRange1')}
        onDatePickerChange={(from: DateObject, to: DateObject) => {
          setValue('dateRange1', `${from}-${to}`)
        }}
      />

      <Center>
        <Divider orientation="horizontal" w="full" />
      </Center>

      {watch('shouldCompareOneDevice') ? (
        <>
          <RangeDatePicker
            {...register('dateRange2')}
            onDatePickerChange={(from: DateObject, to: DateObject) => {
              setValue('dateRange2', `${from}-${to}`)
            }}
          />
        </>
      ) : (
        <>
          <Select
            size="lg"
            placeholder="Select location"
            {...register('locationId2')}
            disabled={!watch('dateRange1')}
          >
            {locations.map(({ id, title }) => (
              <option value={id} key={title}>
                {title}
              </option>
            ))}
          </Select>
          {location2 && (
            <DeviceDropDown
              locationId={location2}
              {...register('deviceId2')}
              devices={secondaryDevices}
            />
          )}
        </>
      )}

      <Button
        mt={8}
        colorScheme="button.primary"
        type="submit"
        disabled={
          watch('shouldCompareOneDevice')
            ? !(watch('deviceId1') && watch('dateRange1') && watch('dateRange2'))
            : !(watch('deviceId1') && watch('deviceId2'))
        }
        w={240}
        h={12}
      >
        Compare
      </Button>
    </Flex>
  )
}

export default CompareForm
