import {
  AccordionPanel,
  Flex,
  Text,
  Box,
  Alert,
  AlertIcon,
  Divider,
} from '@chakra-ui/react'
import React from 'react'
import { COLUMN_NAME } from '../pages/Alarms'
import { Alarm } from '../api/types'
import { formatAlarmDate, getFilteredFields } from '../../../utils/helpers'
import { STATES } from '../../../utils/consts'

type Props = {
  alarm: Alarm
  activeTab: number
}
export const AccordionPanelAlarms = ({ alarm, activeTab }: Props) => {
  const multistateFields = Array.isArray(alarm?.multistate_alarm?.multistate_fields)
    ? alarm.multistate_alarm.multistate_fields
    : []

  const dateMapping = {
    0: alarm?.triggered_at,
    1: alarm?.resolved_at,
    2: alarm?.created_at,
  }

  return (
    <AccordionPanel pb={4} bg="gray.50" borderRadius="md">
      <Flex direction="column" p={4} gap={4}>
        {/* Alarm description */}
        <InfoBox title="Description" content={alarm?.description} />
        <InfoBox title="How to resolve" content={alarm?.instructions} />

        {/* Multistate alarm */}
        {alarm?.type?.name === 'Multistate' && (
          <MultistateFields
            fields={multistateFields}
            multistateType={alarm?.multistate_alarm?.state}
            multistateValue={alarm?.value}
            activeTab={activeTab}
          />
        )}

        {/* Status alarm */}
        {alarm?.type?.name === "Status" && (
          <InfoBox title="Trigger alarm when device status is" content={alarm?.status_alarm?.status ? "ON" : "OFF"} />
        )}

        {/* Level alarm */}
        {alarm?.type?.name === 'Level' && <LevelAlarmDetails alarm={alarm} />}

        {/* Acknowledged info */}
        {alarm?.is_acknowledged && (
          <>
            <InfoBox title="Acknowledged by" content={alarm?.acknowledged_by} />
            <InfoBox
              title="Acknowledged at"
              content={formatAlarmDate(alarm?.acknowledged_at)}
            />
          </>
        )}

        {/* Triggered at info */}
        {(activeTab === 0 || activeTab === 1) && (
          <InfoBox title={COLUMN_NAME[0]} content={formatAlarmDate(dateMapping[0])} />
        )}

        {/* Resolved by info */}
        {activeTab === 1 && (
          <>
            <InfoBox title={COLUMN_NAME[1]} content={formatAlarmDate(dateMapping[1])} />
            <InfoBox title="Resolved by" content={alarm.is_auto_resolved ? "Auto" : alarm?.resolved_by} />
          </>
        )}

        {/* Comments */}
        {(activeTab === 0 || activeTab === 1) && alarm?.comments?.length > 0 && (
          <CommentSection comments={alarm.comments} />
        )}
      </Flex>
    </AccordionPanel>
  )
}

const InfoBox = ({ title, content }) => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="sm">
    <Text fontWeight="bold" mb={1}>
      {title}
    </Text>
    <Text color="gray.700">{content || '-'}</Text>
  </Box>
)

const CustomAlert = ({ status, message }) => (
  <Alert status={status} borderRadius="md" boxShadow="sm">
    <AlertIcon />
    {message}
  </Alert>
)

const MultistateFields = ({ fields, multistateType, multistateValue, activeTab }) => {
  if (fields.length === 0)
    return <CustomAlert status="warning" message="No multistate fields available." />

  const state = STATES?.filter((x) => x.id === Number(multistateType))[0]

  return (
    <Box p={4} bg="white" borderRadius="md">
      <Text fontWeight="bold" mb={2}>
        Multistate fields
      </Text>
      <Divider mb={2} />
      {getFilteredFields(activeTab, multistateValue, fields).map((record) => (
        <Box key={record.id} p={4} bg="white" borderRadius="md" boxShadow="sm" mt={2}>
          <Text fontWeight="medium">
            {state?.valueName}: {record.value}
          </Text>
          <Text fontWeight="medium">
            Severity:{' '}
            <Text as="span" color="gray.700">
              {record.severity}
            </Text>
          </Text>
          <Text fontWeight="medium">
            Description:{' '}
            <Text as="span" color="gray.700">
              {record.description}
            </Text>
          </Text>
        </Box>
      ))}
    </Box>
  )
}

const LevelAlarmDetails = ({ alarm }) => (
  <Box p={4} bg="white" borderRadius="md" boxShadow="sm" backgroundColor="white">
    <DetailRow label="Measurement Parameter" value={alarm?.parameter} />
    <DetailRow
      label="Comparison Operator"
      value={alarm?.level_alarm?.comparison_operator}
    />
    <DetailRow
      label="Comparison Value"
      value={`${alarm?.level_alarm?.comparison_value.toFixed(2)} ${alarm?.measurement_types?.name
        }`}
    />
    {alarm?.level_alarm?.bottom_border_percent &&
      alarm?.level_alarm?.upper_border_percent && (
        <>
          <DetailRow
            label="Hysteresis Upper"
            value={`${alarm.level_alarm.upper_border_percent}%`}
          />
          <DetailRow
            label="Hysteresis Lower"
            value={`${alarm.level_alarm.bottom_border_percent}%`}
          />
        </>
      )}
    {alarm?.value !== undefined && alarm?.value !== null && (
      <DetailRow
        label="Triggered Value"
        value={`${alarm.value.toFixed(2)} ${alarm?.measurement_types?.name}`}
      />
    )}
  </Box>
)

const DetailRow = ({ label, value }) => (
  <Flex gap={2} align="center" mb={2}>
    <Text fontWeight="bold">{label}:</Text>
    <Text fontSize="lg" color="gray.700">
      {value}
    </Text>
  </Flex>
)

const CommentSection = ({ comments }) => (
  <Box p={4} bg="blue.50" borderRadius="md">
    <Text fontWeight="bold" mb={2}>
      Comments
    </Text>
    <Divider mb={2} />
    {comments.map((comment, index) => (
      <Box key={index} p={3} bg="white" borderRadius="md" boxShadow="sm" mt={2}>
        <Flex align="center" mb={2} gap={3}>
          <Text fontSize="sm" fontWeight="bold" color="blue.600">
            {comment.user.name}
          </Text>
          <Text fontSize="xs" color="grey.600">
            {formatAlarmDate(comment.created_at)}
          </Text>
        </Flex>
        <Text color="gray.700">{comment.text}</Text>
      </Box>
    ))}
  </Box>
)
