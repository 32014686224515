import { Flex, Spinner } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import CustomTabs from '../../../app/components/CustomTabs/CustomTabs'
import Header from '../../../app/components/Header'
import Groups from '../components/Groups'
import Locations from '../components/Locations'
import Device from '../components/Device'
import { PeriodTabStrip } from '../../dashboard/components/PeriodTabStrip'
import { DEFAULTPERIOD, PERIODPARAMS } from '../../dashboard/consts'
import { useQueryParams } from '../../dashboard/hooks/useQueryParams'
import { useGetDashboardOverviewQuery } from '../../dashboard/api/dasboardApi'
import NotFound from '../components/NotFound'

const Automations = () => {
  const [index, setIndex] = useState(0)
  const [filteredLocations, setFilteredLocations] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])

  const [queryParams] = useQueryParams(PERIODPARAMS)
  const querySearchParams = { ...DEFAULTPERIOD, ...queryParams }

  const dashboardOverviewData = useGetDashboardOverviewQuery({
    from: querySearchParams.from,
    to: querySearchParams.to,
    period: querySearchParams.period,
  })
  const { data: { results: locations } = { results: [] }, isFetching, isLoading } =
    dashboardOverviewData

  useEffect(() => {
    if (index === 0) {
      setFilteredLocations(locations)
    }
  }, [index, locations])

  const componentMap = {
    0: Locations,
    1: Groups,
    2: Device,
  }

  if (isLoading || isFetching) {
    return (
      <Flex h="100vh" w="100%" justifyContent="space-around" align="center">
        <Spinner size="xl" />
      </Flex>
    )
  }

  if (locations?.length === 0) {
    return <NotFound
      title="No smart locations"
      description="Currently there are no locations that support automation and smart control"
    />
  }

  const props = { isLoading }

  const getComponentProps = (index) => {
    const baseProps = { ...props }

    switch (index) {
      case 0:
        return { ...baseProps, filteredLocations, setIndex, setFilteredLocations }
      case 1:
        return { ...baseProps, filteredLocations, setIndex, setFilteredGroups, querySearchParams }
      case 2:
        return { ...baseProps, filteredGroups, querySearchParams }
      default:
        return baseProps
    }
  }

  const componentProps = getComponentProps(index)

  const Component = componentMap[index]

  return (
    <>
      <Header title="Automations" />
      {locations?.length > 0 && (
        <Flex justify="space-between" align="center">
          <CustomTabs
            tabs={[{ label: 'Locations' }, { label: 'Group' }, { label: 'Device' }]}
            activeIndex={index}
            setActiveIndex={setIndex}
          />
          <PeriodTabStrip />
        </Flex>
      )}
      <Component {...componentProps} />
    </>
  )
}

export default Automations
